import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-cw-date',
    templateUrl: './cw-date.component.html',
    styleUrls: ['./cw-date.component.css'],
})
export class CwDateComponent {
    @Input() controlName: string = '';
    @Input() label: string = '';
    @Input() formGroup!: FormGroup;
    @Input() enterKey: boolean = false;
    @Input() showError: boolean = false;
    @Output() enterKeyed = new EventEmitter();

    isRequired() {
        const control = this.formGroup.get(this.controlName);
        if (!control || !control.validator) return false;

        const validator = control.validator({} as AbstractControl);

        return validator && validator['required'];
    }
}
