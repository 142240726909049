import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-cw-card',
    templateUrl: './cw-card.component.html',
    styleUrls: ['./cw-card.component.css'],
})
export class CwCardComponent {
    @Input() title: string = '';
    @Input() showFooter: boolean = true;
}
