import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-cw-primary-button',
    templateUrl: './cw-primary-button.component.html',
    styleUrls: ['./cw-primary-button.component.css'],
})
export class CwPrimaryButtonComponent {
    @Input() buttonText: string = '';
    @Input() isDisabled: boolean = false;
    @Output() onClick = new EventEmitter();

    clicked() {
        this.onClick.emit();
    }
}
