import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { breakdown } from '../../../interfaces/budget-interfaces';
import { AppConfigService } from '../../../services/app-config/app-config.service';

@Component({
    selector: 'app-budget-modal',
    templateUrl: './budget-modal.component.html',
    styleUrls: ['./budget-modal.component.css'],
})
export class BudgetModalComponent implements OnInit {
    Object = Object;
    total!: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { budgetData: breakdown },
        public appConfig: AppConfigService
    ) {}

    ngOnInit(): void {
        this.total = Object.values(this.data.budgetData.totals)
            .reduce((a, b) => a + +b, 0)
            .toFixed(2);
    }

    findScheduleName = (code: string): string => {
        return (
            this.appConfig.config.budgetData.schedules.find(
                (sched) => sched.code === code
            )?.name || ''
        );
    };
}
