import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { ConfigInterface } from '../../interfaces/config-interface';
import {
    AuthServiceService,
    UserGroups,
} from '../auth-service/auth-service.service';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    apiKey = environment.apiKey;
    apiBaseUrl = environment.apiBaseUrl;
    sites: string[] = [];
    selectedSite = 'century-wharf';
    config!: ConfigInterface;

    constructor(
        public httpClient: HttpClient,
        public authService: AuthServiceService
    ) {
        this.getConfigs();
    }

    async getConfigs(): Promise<void> {
        const groups = await this.authService.getGroups();

        if (groups.includes(UserGroups.Staff)) {
            if (groups.includes('century-wharf')) {
                this.sites = ['century-wharf'];
            } else {
                let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
                firstValueFrom(
                    this.httpClient.get<string[]>(this.apiBaseUrl + '/config', {
                        headers,
                    })
                ).then((sites) => {
                    this.sites = sites;
                });
            }
        }
    }

    async getConfig(siteName: string) {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let params = new HttpParams().set('siteName', siteName);
        this.config = await firstValueFrom(
            this.httpClient.get<ConfigInterface>(this.apiBaseUrl + '/config', {
                headers,
                params,
            })
        );
    }
}
