import { Component, OnInit } from '@angular/core';
import { PropertyDetailsService } from '../../services/property-details/property-details.service';
import { PropertyDetail } from '../../interfaces/property';
import { PropertyFilter } from '../../enums/property';
import { addDays, addMonths, isSameMonth, startOfMonth } from 'date-fns';
import { Router } from '@angular/router';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
    reportType: string | undefined;
    reportData!: PropertyDetail[];
    unitCourts: string[] = [];
    unitHouses: string[] = [];
    overDueOrMonth!: string;
    monthsArray!: string[];
    courtSelected: string[] | undefined;
    houseSelected: string[] | undefined;
    dateSelected: Date[] | undefined;
    viewBy!: string;
    loading: boolean = false;

    reportForm: FormGroup = new FormGroup({
        type: new FormControl(null, Validators.required),
    });

    boilerForm!: FormGroup;

    constructor(
        public propertyService: PropertyDetailsService,
        public router: Router
    ) {}

    async ngOnInit() {
        try {
            this.loading = true;

            this.reportForm.valueChanges.subscribe((value) => {
                this.reportType = value.type;
            });

            this.unitCourts = await this.propertyService.getUnitFilters(
                PropertyFilter.UnitFirst
            );
            this.unitHouses = await this.propertyService.getUnitFilters(
                PropertyFilter.UnitFirst
            );
            this.reportData =
                (await this.propertyService.getReportData()) || [];
            this.populateMonthSelect();

            this.boilerForm = new FormGroup({
                ...Object.fromEntries(
                    this.unitCourts.map((court) => [
                        court,
                        new FormControl(false),
                    ])
                ),
                ...Object.fromEntries(
                    this.monthsArray.map((month) => [
                        month,
                        new FormControl(false),
                    ])
                ),
                reportType: new FormControl('overdue', Validators.required),
            });
        } catch (error: any) {
        } finally {
            this.loading = false;
        }
    }

    populateMonthSelect() {
        const today: Date = new Date();
        const currentMonth: number = today.getMonth();
        const months: any[] = [];

        for (let i = 0; i < 12; i++) {
            months.push(
                addDays(
                    startOfMonth(addMonths(new Date(today), i)),
                    2
                ).toISOString()
            );
        }
        this.monthsArray = months;
    }

    async generateBoilerReport() {
        const selectedCourts = this.unitCourts.filter(
            (court) => this.boilerForm.controls[court].value === true
        );

        let filteredData = this.reportData.filter((property) =>
            selectedCourts.includes(property.unitFirst)
        );

        const type = this.boilerForm.controls['reportType'].value;

        switch (type) {
            case 'overdue':
                const oneYearAgo: Date = new Date();
                oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
                const yearAgoString: string = oneYearAgo.toISOString();
                filteredData = filteredData.filter(
                    (property) =>
                        property?.boilerDetails?.lastServiced < yearAgoString ||
                        property?.boilerDetails?.lastServiced === null
                );
                break;

            case 'month':
                const months = this.monthsArray.filter(
                    (month) => this.boilerForm.controls[month].value === true
                );

                filteredData = filteredData.filter((property) => {
                    if (!property?.boilerDetails?.lastServiced) return false;
                    const expiryDate = new Date(
                        property.boilerDetails.lastServiced
                    );
                    expiryDate.setFullYear(expiryDate.getFullYear() + 1);

                    return months.some((month) => {
                        return isSameMonth(
                            new Date(expiryDate),
                            new Date(month)
                        );
                    });
                });

                console.log(filteredData);
                break;
        }

        this.propertyService.reportDisplayColumns = [
            'unitFull',
            'name',
            'phone',
            'email',
            'lastServiced',
        ];
        this.propertyService.reportFilteredData = filteredData;
        return this.router.navigateByUrl('home-page/report-viewer');
    }

    async generateViewReport() {
        const type = this.boilerForm.controls['reportType'].value;

        let filteredData: PropertyDetail[] = [];
        switch (type) {
            case 'viewAll':
                filteredData = this.reportData;
                break;
            case 'court':
                const selectedCourts = this.unitCourts.filter(
                    (court) => this.boilerForm.controls[court].value === true
                );

                filteredData = this.reportData.filter((property) =>
                    selectedCourts.includes(property.unitFirst)
                );
                break;
        }

        this.propertyService.reportFilteredData = filteredData;
        this.propertyService.reportDisplayColumns = [
            'unitFull',
            'name',
            'phone',
            'email',
        ];
        return this.router.navigateByUrl('home-page/report-viewer');
    }

    async generateSubLetReport() {
        const selectedCourts = this.unitCourts.filter(
            (court) => this.boilerForm.controls[court].value === true
        );

        let filteredData = this.reportData.filter((property) =>
            selectedCourts.includes(property.unitFirst)
        );

        const type = this.boilerForm.controls['reportType'].value;

        switch (type) {
            case 'overdue':
                filteredData = filteredData.filter((property) => {
                    return (
                        property?.subLetDetails.subLetExpiry <
                            new Date().toISOString() &&
                        property?.subLetDetails?.subLet === true
                    );
                });
                break;

            case 'month':
                const months = this.monthsArray.filter(
                    (month) => this.boilerForm.controls[month].value === true
                );

                filteredData = filteredData.filter((property) => {
                    if (
                        !property?.subLetDetails.subLet ||
                        !property?.subLetDetails?.subLetExpiry
                    )
                        return false;
                    const expiryDate = new Date(
                        property.subLetDetails.subLetExpiry
                    );

                    return months.some((month) => {
                        return isSameMonth(
                            new Date(expiryDate),
                            new Date(month)
                        );
                    });
                });
                break;
        }

        this.propertyService.reportDisplayColumns = [
            'unitFull',
            'name',
            'phone',
            'email',
            'subLetExpiry',
        ];
        this.propertyService.reportFilteredData = filteredData;
        return this.router.navigateByUrl('home-page/report-viewer');
    }

    selectAllNone(selectEl: MatSelect) {
        const selected = selectEl.options.find((opt) => opt.selected);
        if (selected) {
            selectEl.options.forEach((item: MatOption) => item.deselect());
        } else {
            selectEl.options.forEach((item: MatOption) => item.select());
        }
    }

    boilerValid(): boolean {
        if (this.reportForm?.controls['type'].value !== 'Boiler Servicing')
            return false;

        if (
            this.unitCourts.every(
                (court) => this.boilerForm?.controls[court].value === false
            )
        )
            return false;

        if (
            this.boilerForm?.controls['reportType'].value === 'month' &&
            this.monthsArray.every(
                (month) => this.boilerForm?.controls[month].value === false
            )
        )
            return false;

        if (
            !['overdue', 'month'].includes(
                this.boilerForm?.controls['reportType'].value
            )
        )
            return false;

        return true;
    }

    viewValid(): boolean {
        if (this.reportForm?.controls['type'].value !== 'View Properties')
            return false;

        if (
            this.boilerForm?.controls['reportType'].value === 'court' &&
            this.unitCourts.every(
                (court) => this.boilerForm?.controls[court].value === false
            )
        )
            return false;

        if (
            !['viewAll', 'court'].includes(
                this.boilerForm?.controls['reportType'].value
            )
        )
            return false;

        return true;
    }

    toggleAll(type: 'court' | 'month') {
        let value: boolean;
        switch (type) {
            case 'court':
                value = !this.boilerForm?.controls[this.unitCourts[0]].value;
                this.unitCourts.forEach((court) =>
                    this.boilerForm.controls[court].setValue(value)
                );
                break;
            case 'month':
                value = !this.boilerForm?.controls[this.monthsArray[0]].value;
                this.monthsArray.forEach((month) =>
                    this.boilerForm.controls[month].setValue(value)
                );
                break;
        }
    }

    subLetValid(): boolean {
        if (this.reportForm?.controls['type'].value !== 'Sub Let') return false;

        if (
            this.unitCourts.every(
                (court) => this.boilerForm?.controls[court].value === false
            )
        )
            return false;

        if (
            this.boilerForm?.controls['reportType'].value === 'month' &&
            this.monthsArray.every(
                (month) => this.boilerForm?.controls[month].value === false
            )
        )
            return false;

        if (
            !['overdue', 'month'].includes(
                this.boilerForm?.controls['reportType'].value
            )
        )
            return false;

        return true;
    }
}
