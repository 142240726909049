import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { WorkOrder } from '../work-orders/work-orders.component';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ContractorDetails } from '../contractor-sign-up/contractor-sign-up.component';
import { NotificationService } from '../../services/notification/notification.service';
import { Router } from '@angular/router';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isToday } from 'date-fns';
import * as Sentry from '@sentry/angular-ivy';
import { AppConfigService } from '../../services/app-config/app-config.service';
import { UpdateServiceService } from 'src/app/services/update/update-service.service';

@Component({
    selector: 'app-dashboard-page',
    templateUrl: './dashboard-page.component.html',
    styleUrls: ['./dashboard-page.component.css'],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
    appVersion: string = '';
    apiVersion: string = '';
    environment: string = '';
    workOrderApprovals: Partial<WorkOrder>[] = [];
    contractorApprovals: Partial<ContractorDetails>[] = [];
    lastUpdated!: string;

    loading: boolean = true;
    destroy$ = new Subject();
    apiKey = environment.apiKey;
    apiBaseUrl = environment.apiBaseUrl;

    constructor(
        public notification: NotificationService,
        public router: Router,
        public auth: AuthServiceService,
        public http: HttpClient,
        public appConfig: AppConfigService,
        public updateService: UpdateServiceService
    ) {}

    ngOnDestroy() {
        this.destroy$.next(true);
    }

    async ngOnInit(): Promise<any> {
        const [appVersion, env] = environment.appVersion.split('+');
        this.apiVersion = await firstValueFrom(this.getVersion());
        this.appVersion = appVersion;
        this.environment = env;

        const lastRefresh = localStorage.getItem('lastRefresh');
        if (!lastRefresh || !isToday(new Date(lastRefresh))) {
            const dateNow = new Date();
            localStorage.setItem('lastRefresh', dateNow.toDateString());
            location.reload();
        }
        const [userName, email] = await this.auth.getUser();
        Sentry.setUser({
            username: userName,
            email,
        });

        const authLimit = await this.auth.getAuthLimit();
        const userGroups = await this.auth.getGroups();
        this.notification.triggerApprovals();

        this.loading = true;
        this.notification?.approvals
            .pipe(takeUntil(this.destroy$))
            .subscribe((approvals) => {
                this.workOrderApprovals =
                    approvals?.workOrder?.filter(
                        (order) =>
                            Object.values(order.leaseGroups as {}).reduce(
                                (a: number, b: any) => a + +b,
                                0
                            ) <= authLimit
                    ) || [];
                this.contractorApprovals = approvals.contractor || [];
                this.lastUpdated = approvals.updatedAt || '';
                this.loading = false;
            });
    }

    getVersion() {
        const headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        return this.http.get<string>(this.apiBaseUrl + '/version', { headers });
    }
}
