<app-page-toolbar [pageTitle]="'Income'"> </app-page-toolbar>

<app-cwe-spinner *ngIf="loading || updating"></app-cwe-spinner>

<div class="mx-auto flex w-full max-w-7xl items-start flex-col">
    <aside class="flex-col shrink-0 ml-0 w-full order-first top-[-20px]">
        <div
            class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 mx-5 mt-5"
        >
            <div
                class="mt-6 flex flex-col justify-center justify-items-stretch border-t border-gray-900/5 gap-x-2 px-6 pb-6 w-full"
            >
                <app-card-title [title]="'Income'"></app-card-title>
                <div class="px-1 flex flex-col">
                    <div class="">
                        <div class="flow-root">
                            <div
                                class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
                            >
                                <div
                                    class="inline-block py-2 align-middle px-6 w-full"
                                >
                                    <div
                                        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
                                    >
                                        <table
                                            *ngIf="
                                                selectedBudgets &&
                                                !loading &&
                                                !updating
                                            "
                                            class="min-w-full divide-y divide-gray-200"
                                        >
                                            <tr>
                                                <th
                                                    scope="col"
                                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[400px]"
                                                ></th>
                                                <th
                                                    scope="col"
                                                    class="pr-6 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]"
                                                >
                                                    <select
                                                        [(ngModel)]="
                                                            selectedYears
                                                        "
                                                        (ngModelChange)="
                                                            updateSelectedBudgets()
                                                        "
                                                        class="border-none rounded-lg focus:ring-cw-blue bg-transparent cursor-pointer"
                                                    >
                                                        <option
                                                            *ngFor="
                                                                let year of budgetYears
                                                            "
                                                            [value]="year"
                                                        >
                                                            {{ year }}
                                                        </option>
                                                    </select>
                                                </th>
                                            </tr>
                                            <tr
                                                class="divide-y divide-gray-200 bg-white hover:bg-gray-100"
                                            >
                                                <th
                                                    scope="row"
                                                    class="py-3.5 pl-4 pr-3 text-left font-medium text-sm text-gray-900 sm:pl-6 w-[400px]"
                                                >
                                                    Expected Service Charge
                                                    Income
                                                </th>
                                                <td
                                                    class="px-3 py-1 text-xs text-gray-500 text-right w-[150px]"
                                                >
                                                    <input
                                                        *ngIf="totalService"
                                                        class="text-gray-900 w-[150px] border-none bg-transparent"
                                                        currencyMask
                                                        [options]="{
                                                            prefix: '£ ',
                                                            allowNegative: false
                                                        }"
                                                        disabled
                                                        [value]="totalService"
                                                    />
                                                </td>
                                            </tr>
                                            <tr
                                                class="divide-y divide-gray-200 bg-white hover:bg-gray-100"
                                            >
                                                <th
                                                    scope="row"
                                                    class="py-3.5 pl-4 pr-3 text-left font-medium text-sm text-gray-900 sm:pl-6 w-[400px]"
                                                >
                                                    Expected Boiler Scheme
                                                    Income
                                                </th>
                                                <td
                                                    class="px-3 py-1 text-xs text-gray-500 text-right w-[150px]"
                                                >
                                                    <input
                                                        *ngIf="totalBoiler"
                                                        class="text-gray-900 w-[150px] border-none bg-transparent"
                                                        currencyMask
                                                        [options]="{
                                                            prefix: '£ ',
                                                            allowNegative: false
                                                        }"
                                                        disabled
                                                        [value]="totalBoiler"
                                                    />
                                                </td>
                                            </tr>
                                            <tr
                                                class="divide-y divide-gray-200 bg-white hover:bg-gray-100"
                                            >
                                                <th
                                                    scope="row"
                                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-6 w-[400px]"
                                                >
                                                    Total Expected Income
                                                </th>
                                                <td
                                                    class="px-3 py-1 text-xs text-gray-500 text-right w-[150px]"
                                                >
                                                    <input
                                                        *ngIf="totalExp"
                                                        class="text-gray-900 w-[150px] font-bold border-none bg-transparent"
                                                        currencyMask
                                                        [options]="{
                                                            prefix: '£ ',
                                                            allowNegative: false
                                                        }"
                                                        disabled
                                                        [value]="totalExp"
                                                    />
                                                </td>
                                            </tr>
                                            <tr
                                                class="divide-y divide-gray-200 bg-white"
                                            >
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr
                                                class="divide-y divide-gray-200 bg-white hover:bg-gray-100"
                                            >
                                                <th
                                                    scope="row"
                                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-6 w-[400px]"
                                                >
                                                    Service Charge Income
                                                    Received
                                                </th>
                                                <td
                                                    class="px-3 py-1 text-xs text-gray-500 text-right w-[150px]"
                                                >
                                                    <input
                                                        *ngIf="totalRec"
                                                        class="text-gray-900 w-[150px] border-none bg-transparent"
                                                        currencyMask
                                                        [options]="{
                                                            prefix: '£ ',
                                                            allowNegative: false
                                                        }"
                                                        disabled
                                                        [value]="
                                                            serviceChargeReceived
                                                        "
                                                    />
                                                </td>
                                            </tr>
                                            <tr
                                                class="divide-y divide-gray-200 bg-white hover:bg-gray-100"
                                            >
                                                <th
                                                    scope="row"
                                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-6 w-[400px]"
                                                >
                                                    Other Income Received
                                                </th>
                                                <td
                                                    class="px-3 py-1 text-xs text-gray-500 text-right w-[150px]"
                                                >
                                                    <input
                                                        *ngIf="totalRec"
                                                        class="text-gray-900 w-[150px] border-none bg-transparent"
                                                        currencyMask
                                                        [options]="{
                                                            prefix: '£ ',
                                                            allowNegative: false
                                                        }"
                                                        disabled
                                                        [value]="otherRec"
                                                    />
                                                </td>
                                            </tr>

                                            <tr
                                                class="divide-y divide-gray-200 bg-white hover:bg-gray-100"
                                            >
                                                <th
                                                    scope="row"
                                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-6 w-[400px]"
                                                >
                                                    Total Income Received
                                                </th>
                                                <td
                                                    class="px-3 py-1 text-xs text-gray-500 text-right w-[150px]"
                                                >
                                                    <input
                                                        *ngIf="totalExp"
                                                        class="text-gray-900 w-[150px] font-bold border-none bg-transparent"
                                                        currencyMask
                                                        [options]="{
                                                            prefix: '£ ',
                                                            allowNegative: false
                                                        }"
                                                        disabled
                                                        [value]="totalRec"
                                                    />
                                                </td>
                                            </tr>
                                            <tr
                                                class="divide-y divide-gray-200 bg-white"
                                            >
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr
                                                class="divide-y divide-gray-200 bg-white hover:bg-gray-100"
                                            >
                                                <th
                                                    scope="row"
                                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-6 w-[400px]"
                                                >
                                                    Service / Boiler Charges
                                                    Outstanding
                                                </th>
                                                <td
                                                    class="px-3 py-1 text-xs text-gray-500 text-right w-[150px]"
                                                >
                                                    <input
                                                        *ngIf="totalExp"
                                                        class="text-gray-900 w-[150px] font-bold border-none bg-transparent"
                                                        currencyMask
                                                        [options]="{
                                                            prefix: '£ ',
                                                            allowNegative: false
                                                        }"
                                                        disabled
                                                        [value]="totalOut"
                                                    />
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <dl class="flex flex-wrap">
                <div class="flex-auto px-6 pt-6">
                    <app-income-chart
                        *ngIf="true"
                        [budgetYear]="selectedYears"
                        [allUnits]="allUnits"
                        [totalExp]="+totalExp"
                    ></app-income-chart>
                </div>
            </dl>
        </div>
    </aside>

    <div
        class="box-border w-full px-5 flex flex-col tablet:flex-col desktop:flex-row gap-x-5 mb-5"
    >
        <div
            class="box-border w-full desktop:w-1/3 shadow ring-1 shrink-0 ring-black ring-opacity-5 sm:rounded-lg mt-5 px-6 pb-6 bg-gray-50"
        >
            <app-card-title [title]="'Add Income'"></app-card-title>

            <app-cw-date
                [label]="'Date'"
                [formGroup]="paymentForm"
                [controlName]="'date'"
                [showError]="true"
            ></app-cw-date>
            <app-cw-text-area
                [label]="'Description'"
                [formGroup]="paymentForm"
                [controlName]="'description'"
                [showError]="true"
            ></app-cw-text-area>
            <app-cw-currency
                [label]="'Amount'"
                [formGroup]="paymentForm"
                [controlName]="'amount'"
                [showError]="true"
            ></app-cw-currency>
            <div class="flex-row justify-end">
                <app-cw-primary-button
                    [buttonText]="'Add'"
                    class="w-2/5"
                    (onClick)="addTransaction()"
                    [isDisabled]="paymentForm.invalid || loading"
                ></app-cw-primary-button>
            </div>
            <app-banner
                *ngIf="messageBanner"
                [eventType]="messageBanner"
                [message]="messageText"
            ></app-banner>
        </div>

        <div
            class="box-border flex-grow shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg mt-5 px-6 pb-6 bg-gray-50"
        >
            <app-card-title [title]="'Other Income'"></app-card-title>

            <div class="">
                <div class="flow-root">
                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div
                            class="inline-block py-2 align-middle sm:px-6 lg:px-8 w-full"
                        >
                            <div
                                class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
                            >
                                <table
                                    class="min-w-full divide-y divide-gray-300"
                                >
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-[300px]"
                                            >
                                                Description
                                            </th>
                                            <th
                                                scope="col"
                                                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Amount
                                            </th>

                                            <th
                                                scope="col"
                                                class="relative py-3.5 pl-3 pr-4 sm:pr-6 w-4"
                                            >
                                                <span class="sr-only"
                                                    >Delete</span
                                                >
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                        class="divide-y divide-gray-200 bg-white"
                                    >
                                        <tr
                                            *ngFor="
                                                let payment of paymentArray;
                                                index as i
                                            "
                                            (mouseenter)="
                                                paymentButtons[i] = true
                                            "
                                            (mouseleave)="
                                                paymentButtons[i] = false
                                            "
                                            class="hover:bg-gray-100"
                                        >
                                            <td
                                                class="whitespace-nowrap py-1 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6"
                                            >
                                                {{ payment.date | date }}
                                            </td>
                                            <td
                                                class="px-3 py-1 text-xs text-gray-500 max-w-[300px]"
                                            >
                                                <span
                                                    class="overflow-ellipsis"
                                                    >{{
                                                        payment.description
                                                    }}</span
                                                >
                                            </td>
                                            <td
                                                class="whitespace-nowrap px-3 py-1 text-xs text-gray-500"
                                            >
                                                {{
                                                    payment.amount
                                                        | currency: 'GBP'
                                                }}
                                            </td>
                                            <td
                                                class="relative whitespace-nowrap py-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                                            >
                                                <button
                                                    #deleteButton
                                                    (click)="
                                                        deleteTransaction(
                                                            payment.id,
                                                            payment.description
                                                        )
                                                    "
                                                    class="deleteButton"
                                                    *ngIf="
                                                        userGroups.includes(
                                                            'PaymentAdmin'
                                                        )
                                                    "
                                                    color="warn"
                                                    [disabled]="
                                                        !paymentButtons[i]
                                                    "
                                                    mat-icon-button
                                                >
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
