import { Pipe, PipeTransform } from '@angular/core';
import {startCase} from "lodash";


@Pipe({
  name: 'kebabCase'
})
export class KebabCasePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return startCase(value.replace(/-/g, ' '));
  }

}
