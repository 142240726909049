import {
    APP_INITIALIZER,
    ErrorHandler,
    NgModule,
    NO_ERRORS_SCHEMA,
    isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { HomeComponent } from './pages/home/home.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { PropertyDetailsPageComponent } from './pages/property-details-page/property-details-page.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PropertyResultComponent } from './pages/property-details-page/property-result/property-result.component';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NoteModalComponent } from './components/modals/note-modal/note-modal.component';
import { InfoModalComponent } from './components/modals/info-modal/info-modal.component';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { NgPipesModule } from 'ngx-pipes';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { ReportsComponent } from './pages/reports/reports.component';
import {
    MAT_LEGACY_RADIO_DEFAULT_OPTIONS as MAT_RADIO_DEFAULT_OPTIONS,
    MatLegacyRadioModule as MatRadioModule,
} from '@angular/material/legacy-radio';
import { ReportViewerComponent } from './pages/reports/report-viewer/report-viewer.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { UnderscorePipe } from './pipes/underscore/underscore.pipe';
import { MatBadgeModule } from '@angular/material/badge';
import { ContractorSignUpComponent } from './pages/contractor-sign-up/contractor-sign-up.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { ContractorsComponent } from './pages/contractors/contractors.component';
import { ViewContractorComponent } from './pages/contractors/view-contractor/view-contractor.component';
import { ContractorFileUploadComponent } from './components/contractor-file-upload/contractor-file-upload.component';
import { WorkOrdersComponent } from './pages/work-orders/work-orders.component';
import { CreateWorksOrderComponent } from './pages/work-orders/create-works-order/create-works-order.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatSortModule } from '@angular/material/sort';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ViewWorkOrderComponent } from './pages/work-orders/view-work-order/view-work-order.component';
import { ContractorDashboardComponent } from './pages/contractor-dashboard/contractor-dashboard.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { BudgetsComponent } from './pages/finance/budgets/budgets.component';
import { ExpensesComponent } from './pages/finance/expenses/expenses.component';
import { IncomeComponent } from './pages/finance/income/income.component';
import { EditBudgetComponent } from './pages/finance/budgets/edit-budget/edit-budget.component';
import { VarianceComponent } from './pages/finance/variance/variance.component';
import { PageToolbarComponent } from './components/page-toolbar/page-toolbar.component';
import { ContractorWorkOrderComponent } from './components/contractor-work-order/contractor-work-order.component';
import { FooterToolbarComponent } from './components/footer-toolbar/footer-toolbar.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { CweSpinnerComponent } from './components/cwe-spinner/cwe-spinner.component';
import { NgOptimizedImage } from '@angular/common';
import { BudgetModalComponent } from './components/modals/budget-modal/budget-modal.component';
import { PaymentModalComponent } from './components/modals/payment-modal/payment-modal.component';
import { FinanceReportsComponent } from './pages/finance/reports/reports.component';
import { InvoiceRunComponent } from './pages/finance/reports/invoice-run/invoice-run.component';
import { AuditModalComponent } from './components/modals/audit-modal/audit-modal.component';
import { BankDetailsModalComponent } from './components/modals/bank-details-modal/bank-details-modal.component';
import { AuthInterceptorInterceptor } from './auth-interceptor.interceptor';
import { PasswordModalComponent } from './components/modals/password-modal/password-modal.component';
import { ForgotPasswordModalComponent } from './components/modals/forgot-password-modal/forgot-password-modal.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';
import { InviteComponentComponent } from './components/invite-component/invite-component.component';
import { NoAccountContractorComponent } from './components/no-account-contractor/no-account-contractor.component';
import { LeaseholderComponent } from './pages/leaseholder/leaseholder.component';
import { AccountModalComponent } from './components/modals/account-modal/account-modal.component';
import { PaymentsComponent } from './pages/finance/reports/payments/payments.component';
import { DebtorsComponent } from './pages/finance/reports/debtors/debtors.component';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { NgApexchartsModule } from 'ng-apexcharts';
import { VarianceChartComponent } from './components/variance-chart/variance-chart.component';
import { IncomeChartComponent } from './components/income-chart/income-chart.component';
import { AdminPanelComponent } from './pages/admin-panel/admin-panel.component';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { KebabCasePipe } from './pipes/kebab/kebab-case.pipe';
import { CwSelectComponent } from './components/form/cw-select/cw-select.component';
import { CwTextComponent } from './components/form/cw-text/cw-text.component';
import { CwCardComponent } from './components/structure/cw-card/cw-card.component';
import { CwPrimaryButtonComponent } from './components/buttons/cw-primary-button/cw-primary-button.component';
import { CwSecondaryButtonComponent } from './components/buttons/cw-secondary-button/cw-secondary-button.component';
import { CwDateComponent } from './components/form/cw-date/cw-date.component';
import { CwTextAreaComponent } from './components/form/cw-text-area/cw-text-area.component';
import { CwCurrencyComponent } from './components/form/cw-currency/cw-currency.component';
import { CardTitleComponent } from './components/card-title/card-title.component';
import { BannerComponent } from './components/banner/banner.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PropAuditsPipe } from './pipes/prop-audits/prop-audits.pipe';
import { FormSectionComponent } from './components/form/form-section/form-section.component';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';

@NgModule({
    declarations: [
        AppComponent,
        LandingPageComponent,
        HomeComponent,
        NavigationComponent,
        DashboardPageComponent,
        PropertyDetailsPageComponent,
        PropertyResultComponent,
        NoteModalComponent,
        InfoModalComponent,
        ConfirmModalComponent,
        FileUploadComponent,
        ReportsComponent,
        ReportViewerComponent,
        UnderscorePipe,
        ContractorSignUpComponent,
        ContractorsComponent,
        ViewContractorComponent,
        ContractorFileUploadComponent,
        WorkOrdersComponent,
        CreateWorksOrderComponent,
        ViewWorkOrderComponent,
        ContractorDashboardComponent,
        BudgetsComponent,
        ExpensesComponent,
        IncomeComponent,
        EditBudgetComponent,
        VarianceComponent,
        PageToolbarComponent,
        ContractorWorkOrderComponent,
        FooterToolbarComponent,
        CweSpinnerComponent,
        BudgetModalComponent,
        PaymentModalComponent,
        FinanceReportsComponent,
        InvoiceRunComponent,
        AuditModalComponent,
        BankDetailsModalComponent,
        PasswordModalComponent,
        ForgotPasswordModalComponent,
        InviteComponentComponent,
        NoAccountContractorComponent,
        LeaseholderComponent,
        AccountModalComponent,
        PaymentsComponent,
        DebtorsComponent,
        VarianceChartComponent,
        IncomeChartComponent,
        AdminPanelComponent,
        KebabCasePipe,
        CwSelectComponent,
        CwTextComponent,
        CwCardComponent,
        CwPrimaryButtonComponent,
        CwSecondaryButtonComponent,
        CwDateComponent,
        CwTextAreaComponent,
        CwCurrencyComponent,
        CardTitleComponent,
        BannerComponent,
        PropAuditsPipe,
        FormSectionComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatCardModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatButtonModule,
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        MatSelectModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatStepperModule,
        MatTooltipModule,
        MatDialogModule,
        FormsModule,
        NgPipesModule,
        NgxMatFileInputModule,
        MatRadioModule,
        MatTableModule,
        MatPaginatorModule,
        ClipboardModule,
        MatBadgeModule,
        MatCheckboxModule,
        MatGridListModule,
        MatTabsModule,
        MatSortModule,
        CurrencyMaskModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        NgOptimizedImage,
        LoggerModule.forRoot({
            level: NgxLoggerLevel.ERROR,
            serverLogLevel: NgxLoggerLevel.DEBUG,
            serverLoggingUrl: environment.apiBaseUrl + '/logging',
        }),
        MatSliderModule,
        NgApexchartsModule,
        MatSlideToggleModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.environment !== 'development',
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorInterceptor,
            multi: true,
        },
        { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'accent' } },
        ...(environment.environment !== 'development'
            ? [
                  {
                      provide: ErrorHandler,
                      useValue: Sentry.createErrorHandler({
                          showDialog: false,
                      }),
                  },
              ]
            : []),
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
