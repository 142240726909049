<div class="divide-y divide-gray-200 overflow-visible rounded-lg bg-slate-50 shadow m-5 mt-0">
  <div class="px-4 py-5 sm:px-6 flex">
    <ng-content select="card-icon"></ng-content>
    <ng-content select="card-icon"></ng-content>
    <h3 *ngIf="title" class="font-poppins leading-6 text-gray-900 m-0 ml-3">{{ title }}</h3>
  </div>
  <div class="px-4 py-5 sm:p-6">
    <ng-content select="card-body"></ng-content>
  </div>

  <div class="px-4 py-4 sm:px-6" *ngIf="showFooter">
    <ng-content select="card-footer"></ng-content>
  </div>
</div>
