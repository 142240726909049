<app-page-toolbar
    [appVersion]="appVersion"
    [apiVersion]="apiVersion"
    [environment]="environment"
    [pageTitle]="'Dashboard'"
>
</app-page-toolbar>

<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon>notifications</mat-icon>
            Notifications
        </mat-card-title>
    </mat-card-header>
    <mat-card-content> </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon slot="start">task_alt</mat-icon>
            Approvals
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <small
            *ngIf="
                (workOrderApprovals.length === 0 &&
                    contractorApprovals.length === 0 &&
                    !loading) ||
                (!loading && appConfig.selectedSite !== 'century-wharf')
            "
            >There is nothing waiting for approval</small
        >
        <small *ngIf="loading">Loading Approvals...</small>

        <ng-container *ngIf="appConfig.selectedSite === 'century-wharf'">
            <mat-card
                *ngFor="let approval of workOrderApprovals"
                [routerLink]="[
                    '/home-page/view-work-order',
                    approval.orderNumber
                ]"
                class="approval"
            >
                <mat-label class="labelCol">Work Order</mat-label>
                <mat-label class="labelCol">{{ approval.status }}</mat-label>
                <mat-label class="labelCol"
                    >#{{ approval.orderNumber }}</mat-label
                >
            </mat-card>

            <mat-card
                *ngFor="let approval of contractorApprovals"
                [routerLink]="[
                    '/home-page/view-contractor',
                    approval.applicationId
                ]"
                class="approval"
            >
                <mat-label class="labelCol">Contractor Application</mat-label>
                <mat-label class="labelCol">Awaiting Approval</mat-label>
                <mat-label class="labelCol">{{
                    approval.companyName
                }}</mat-label>
            </mat-card>
        </ng-container>
    </mat-card-content>
</mat-card>

<footer>
    <mat-card id="lastUpdated">
        <button
            (click)="notification.triggerApprovals()"
            [disabled]="notification.updating"
            id="refreshButton"
            mat-icon-button
        >
            <mat-icon>refresh</mat-icon>
        </button>
        <small slot="end"
            >Last Updated:
            {{
                notification.updating
                    ? 'Updating...'
                    : (lastUpdated | date: 'dd-MM-yyyy, h:mm:ss a')
            }}
        </small>
    </mat-card>
</footer>
