<div class="page-container">
  <div id="imgContainer">
    <mat-card class="fade-in">
      <img alt="" id="logo" src="assets/navLogo.png" />
      <form id="formDiv">
        <div [formGroup]="loginForm"
             class="relative my-4">
          <label for="email"
                 class="absolute -top-2 left-2 inline-block bg-[#252020] px-1 text-xs font-medium text-slate-50">E-mail*</label>
          <input type="text" id="email" name="email" formControlName="email"
                 (keydown.enter)="login()"
                 class="block font-poppins h-12 bg-[#252020] w-full rounded-md border-0 py-1.5 text-slate-50 shadow-sm ring-1 ring-inset ring-gray-300 text-lg  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cw-blue sm:text-sm sm:leading-6">

        </div>

        <div [formGroup]="loginForm"
             class="relative my-4">
          <label for="password"
                 class="absolute -top-2 left-2 inline-block bg-[#252020] px-1 text-xs font-medium text-slate-50">Password*</label>
          <input #passwordInput type="password" id="password" name="password" formControlName="password"
                 (keydown.enter)="login()"
                 class="block font-poppins h-12 bg-[#252020] w-full rounded-md border-0 py-1.5 text-slate-50 shadow-sm ring-1 ring-inset ring-gray-300 text-lg focus:ring-2 focus:ring-inset focus:ring-cw-blue sm:text-sm sm:leading-6">

          <span (click)="passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password'"
                class="pointer-events-auto cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3 z-50 bg-transparent">
            <svg *ngIf="passwordInput.type === 'password'" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="size-6 text-slate-50 bg-[#252020]">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
            <svg *ngIf="passwordInput.type === 'text'" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="size-6 text-slate-50 bg-[#252020]">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>


          </span>
        </div>


        <app-cw-primary-button [buttonText]="'Sign In'" (onClick)="login()" [isDisabled]="loginForm.invalid">Sign In
        </app-cw-primary-button>
        <br />
        <a (click)="forgottenPassword()" class="forgotP">
          <mat-label>Forgotten Password?</mat-label>
        </a>
      </form>
    </mat-card>
  </div>
</div>
