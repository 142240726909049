import { Injectable } from '@angular/core';
import { ContractorDetails } from '../../pages/contractor-sign-up/contractor-sign-up.component';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { PropertyDetailsService } from '../property-details/property-details.service';
import { AuditService } from '../audit/audit.service';

@Injectable({
    providedIn: 'root',
})
export class ContractorsService {
    apiBaseUrl = environment.apiBaseUrl;
    apiKey = environment.apiKey;

    constructor(
        public http: HttpClient,
        public propertyDetails: PropertyDetailsService,
        public audit: AuditService
    ) {}

    callContractorApplicationEndpoint(
        data: Partial<ContractorDetails>
    ): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        return this.http.post<ContractorDetails>(
            this.apiBaseUrl + '/contractor/application',
            data,
            {
                headers,
                observe: 'response',
            }
        );
    }

    getPresignedUrl(key: string, operation: string): Observable<string> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let body = { key, operation: operation, bucket: 'contractor' };
        return this.http.post<string>(this.apiBaseUrl + '/presign/', body, {
            headers,
        });
    }

    getPresignedUrlNoAuth(key: string, operation: string): Observable<string> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let body = { key, operation: operation, bucket: 'contractor' };
        return this.http.post<string>(
            this.apiBaseUrl + '/presignnoauth/',
            body,
            {
                headers,
            }
        );
    }

    getContractorList(): Observable<Partial<ContractorDetails>[]> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        return this.http.get<Partial<ContractorDetails>[]>(
            this.apiBaseUrl + '/contractor/',
            { headers }
        );
    }

    getContractorDetails(applicationId: string): Observable<ContractorDetails> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let params = new HttpParams().append('applicationId', applicationId);
        return this.http.get<ContractorDetails>(
            this.apiBaseUrl + '/contractor/id/',
            { headers, params }
        );
    }

    updateContractor(
        updateData: Partial<ContractorDetails>
    ): Observable<HttpResponse<any>> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        return this.http.post<string>(
            this.apiBaseUrl + '/contractor/',
            updateData,
            { headers, observe: 'response' }
        );
    }

    deleteFile(key: string) {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let body = { key, bucket: 'contractor' };
        return this.http.post<string>(this.apiBaseUrl + '/deletefile/', body, {
            headers,
        });
    }

    async getFileList(
        applicationId: string
    ): Promise<Observable<{ fileKey: string; metaData: any }[]>> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let params = new HttpParams().append('applicationId', applicationId);
        return this.http.get<{ fileKey: string; metaData: any }[]>(
            this.apiBaseUrl + '/filelist/',
            { headers, params }
        );
    }

    getContractorApprovals(): Observable<Partial<ContractorDetails>[]> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        return this.http.get<Partial<ContractorDetails>[]>(
            this.apiBaseUrl + '/contractor/approvals/',
            { headers }
        );
    }

    inviteContractor(email: string) {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let params = new HttpParams().append(
            'email',
            encodeURIComponent(email)
        );
        return this.http.get(this.apiBaseUrl + '/contractor/invite', {
            headers,
            params,
        });
    }

    updateMeta(key: string, expiry: string | undefined) {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let body = { key, expiry };
        return this.http.post<string>(this.apiBaseUrl + '/updatemeta/', body, {
            headers,
        });
    }
}
