<app-page-toolbar
  [backButtonRoute]="
        cameFrom === 'search'
            ? '/home-page/property-search'
            : '/home-page/report-viewer'
    "
>
</app-page-toolbar>


<div class="mx-auto flex w-full max-w-7xl items-start mobile:flex-col tablet:flex-row desktop:flex-row">
  <app-cw-card class="flex-1 p-0 m-0 mobile:w-full mt-5" [showFooter]="false">
    <card-icon class="w-full">
      <div class="w-full">
        <div class="block w-full">
          <nav class="flex space-x-1 w-full" aria-label="Tabs">
            <a (click)="tabSelected='details'"
               [ngClass]="tabSelected === 'details' ? 'bg-cw-blue text-white':'hover:text-gray-700'"
               class="rounded-md px-3 py-2 text-sm font-medium cursor-pointer w-[25%] text-center">Details</a>
            <a *ngIf="propertyForm && !propertyForm.disabled" (click)="tabSelected='account'"
               [ngClass]="tabSelected === 'account' ? 'bg-cw-blue text-white':'hover:text-gray-700'"
               class="rounded-md px-3 py-2 text-sm font-medium cursor-pointer w-[25%] text-center">Account</a>
            <a (click)="tabSelected='files'"
               [ngClass]="tabSelected === 'files' ? 'bg-cw-blue text-white':'hover:text-gray-700'"
               class="rounded-md px-3 py-2 text-sm font-medium cursor-pointer w-[25%] text-center"
               aria-current="page">Files</a>
            <a (click)="tabSelected='activity'"
               [ngClass]="tabSelected === 'activity' ? 'bg-cw-blue text-white':'hover:text-gray-700'"
               class="rounded-md px-3 py-2 text-sm font-medium cursor-pointer w-[25%] text-center"
               aria-current="page">Activity</a>
          </nav>
        </div>
      </div>
    </card-icon>

    <card-body>
      <div *ngIf="propertyForm && propertyConfig" [formGroup]="propertyForm" [hidden]="tabSelected !== 'details'"
      >

        <ng-container *ngFor="let section of propertyConfig"
                      class=" border-0 text-gray-900 p-3">

          <app-form-section [section]="section" [form]="propertyForm"
                            [propertyDetails]="propertyDetails"></app-form-section>

        </ng-container>
      </div>

      <div [hidden]="tabSelected !== 'account'" *ngIf="paymentForm" [formGroup]="paymentForm">
        <div class="w-full flex justify-center items-center flex-col desktop:flex-row">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg p-4 w-full desktop:w-1/2">
            <app-card-title [title]="'Add Transaction'"></app-card-title>

            <app-cw-select [label]="'Type'" [formGroup]="paymentForm" [controlName]="'transactionType'"
                           [options]="['Payment', 'Charge', 'Refund Payment', 'Refund Charge']"
                           [showError]="true"></app-cw-select>
            <app-cw-date [label]="'Date'" [formGroup]="paymentForm" [controlName]="'date'"
                         [showError]="true"></app-cw-date>
            <app-cw-text-area [label]="'Description'" [formGroup]="paymentForm"
                              [controlName]="'description'" [showError]="true"></app-cw-text-area>
            <app-cw-currency [label]="'Amount'" [formGroup]="paymentForm" [controlName]="'amount'"
                             [showError]="true"></app-cw-currency>
            <div class="flex-row justify-end">
              <app-cw-primary-button [buttonText]="'Add'" class="w-2/5" (onClick)="addTransaction()"
                                     [isDisabled]="paymentForm.invalid || loading"></app-cw-primary-button>

            </div>
          </div>

          <div
            class=" h-[100%] w-full tablet:w-1/2 flex flex-row justify-center gap-x-2 mt-4 desktop:m-0">
            <h3 class="text-center m-0">Account Balance</h3>
            <h3 class="text-center font-poppins font-bold m-0">{{ accountBalance | currency: 'GBP' }}</h3>
          </div>
        </div>

        <app-card-title [title]="'Account History'"></app-card-title>


        <div class="">
          <div class=" flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block  py-2 align-middle sm:px-6 lg:px-8 w-full">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Date
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-[300px]">
                        Description
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Amount</th>
                      <th scope="col"
                          class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden desktop:table-cell">
                        Balance
                      </th>
                      <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6 w-4">
                        <span class="sr-only">Delete</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                    <tr *ngFor="let payment of paymentArray; index as i" (mouseenter)="paymentButtons[i] = true"
                        (mouseleave)="paymentButtons[i] = false" class="hover:bg-gray-100">
                      <td class="whitespace-nowrap py-1 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                        {{
                          payment.date | date
                        }}
                      </td>
                      <td class=" px-3 py-1 text-xs text-gray-500 max-w-[300px] "><span class="overflow-ellipsis">{{
                          payment.description
                        }}</span>
                      </td>
                      <td class="whitespace-nowrap px-3 py-1 text-xs text-gray-500 ">{{
                          ['Payment', 'Refund Charge'].includes(
                            payment.transactionType
                          )
                            ? '- '
                            : ''
                        }}{{ payment.amount | currency: 'GBP' }}
                      </td>
                      <td class="whitespace-nowrap px-3 py-1 text-xs text-gray-500 hidden desktop:table-cell">
                        {{ calculateBalance(paymentArray, i) | currency: 'GBP' }}
                      </td>
                      <td class="relative whitespace-nowrap py-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button
                          #deleteButton
                          (click)="
                            deleteTransaction(payment.id, payment.description)
                        "
                          class="deleteButton"
                          *ngIf="userGroups.includes('PaymentAdmin')"
                          color="warn"
                          [disabled]="
                            !paymentButtons[i] ||
                            dateBeforeMin(payment.date) ||
                            isAutoCharge(payment.description)
                        "
                          mat-icon-button
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                      </td>
                    </tr>


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div [hidden]="tabSelected !== 'files'" *ngIf="propertyForm">
        <app-card-title [title]="'Upload a File'"></app-card-title>

        <form class="my-4">
          <label for="file-input" class="sr-only">Choose file</label>
          <input #fileUploadInput type="file" accept="application/pdf" name="file-input" id="file-input"
                 [disabled]="propertyForm.disabled"
                 (change)="handleChange($event)"
                 class="cursor-pointer block w-full border border-gray-200 mb-4 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none
    file:bg-gray-300 file:border-0
    file:me-4
    file:py-3 file:px-4
   ">
          <p *ngIf="fileToUpload && !isCorrectType(['application/pdf'], fileToUpload)" class="text-center text-red-500">
            Unsupported file type. Please upload PDF</p>
          <p *ngIf="fileToUpload && fileExists" class="text-center text-red-500">Filename already exists</p>
          <app-cw-primary-button [buttonText]="'Upload'" [isDisabled]="
                !fileToUpload ||
                uploading   || fileExists ||
                (!!fileToUpload && !isCorrectType(['application/pdf'], fileToUpload))
            "
                                 (onClick)="uploadFile(fileToUpload)"></app-cw-primary-button>
        </form>
        <div *ngIf="uploading" class='w-full  bg-gray-100 rounded-3xl h-2.5 '>
          <div class='bg-indigo-600 h-2.5 rounded-3xl' style='width: 50%'></div>
        </div>

        <app-card-title [title]="'Property Files'"></app-card-title>


        <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
            <li class="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6"
                *ngFor="let file of propertyFiles">
              <div class="flex w-0 flex-1 items-center">
                <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                     aria-hidden="true">
                  <path fill-rule="evenodd"
                        d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                        clip-rule="evenodd" />
                </svg>
                <div class="ml-4 flex min-w-0 flex-1 gap-2 hover:text-cw-blue cursor-pointer">
                  <span class="truncate font-medium"
                        (click)="openFile(file); $event.preventDefault()">{{ file.split('/').slice(-1)[0] | underscore }}</span>
                </div>
              </div>
              <div class="ml-4 flex-shrink-0">
                <button type="button" (click)="deleteFile(file)"
                        *ngIf="!propertyForm.disabled"
                        class="rounded-full bg-transparent text-cw-blue p-1  hover:bg-cw-blue-light hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                  </svg>

                </button>
              </div>
            </li>
          </ul>
        </dd>

        <app-card-title [title]="'Service Charges'"></app-card-title>

        <form class="flex flex-col tablet:flex-row gap-x-2 justify-center items-center mb-4">
          <div class="relative w-full mb-4 tablet:w-3/5 tablet:mb-0">
            <label for="update-sc"
                   class="absolute -top-2 left-2 inline-block bg-slate-50 px-1 text-xs font-medium text-gray-900">Update
              Service Charge</label>
            <select #serviceChargeSelect id="update-sc" name="update-sc" [(ngModel)]="selectSC"
                    class="block font-poppins h-12 bg-slate-50 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cw-blue sm:text-sm sm:leading-6">

              <option *ngFor="let demand of serviceCharges"
                      [value]="demand"
              >{{ demand | underscore | ucwords }}
              </option>

            </select>
          </div>


          <app-cw-primary-button [buttonText]="'Update'" class="w-full tablet:w-2/5 tablet:h-full"
                                 [isDisabled]="propertyForm.disabled || serviceCharges.length < 1 || !selectSC"
                                 (onClick)="updateDemand(selectSC)"></app-cw-primary-button>
        </form>

        <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
            <li class="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6"
                *ngFor="let file of serviceCharges">
              <div class="flex w-0 flex-1 items-center">
                <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor"
                     aria-hidden="true">
                  <path fill-rule="evenodd"
                        d="M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z"
                        clip-rule="evenodd" />
                </svg>
                <div class="ml-4 flex min-w-0 flex-1 gap-2 hover:text-cw-blue cursor-pointer">
                  <span class="truncate font-medium"
                        (click)="openServiceCharge(file); $event.preventDefault()">{{ startCase(file | underscore) }}</span>
                </div>
              </div>

            </li>
          </ul>
        </dd>
      </div>


      <div [hidden]="tabSelected !== 'activity'" *ngIf="propertyForm">
        <div class="mt-5 px-1 flex flex-col">

          <div class="gap-x-3 mb-4">


            <form class="relative flex-auto">
              <div
                class="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-500 focus-within:ring-2 focus-within:ring-cw-blue">
                <label for="comment" class="sr-only">Add your comment</label>
                <textarea rows="2" name="comment" id="comment" [(ngModel)]="commentBox"
                          class="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="Add your comment..." [disabled]="propertyForm.disabled"></textarea>
              </div>

              <div class="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                <div class="flex items-center space-x-5">

                  <div class="flex items-center">
                    <div>
                      <div class="relative">
                      </div>
                    </div>
                  </div>
                </div>
                <button (click)="addAudit()"
                        [disabled]="!commentBox || commentBox.length < 1 || addingAudit || propertyForm.disabled"
                        class="rounded-md bg-cw-blue px-2.5 py-1.5 text-white text-sm font-poppins shadow-sm ring-1 ring-inset  hover:bg-cw-blue-light disabled:opacity-30">
                  {{ addingAudit ? 'Saving...' : 'Add Comment' }}
                </button>
              </div>
            </form>
          </div>

          <ul role="list" class="space-y-4  overflow-hidden ">
            <li class="relative flex gap-x-3 mt-0" *ngFor="let audit of (propertyAudits | reverse);  index as i;">
              <ng-container>
                <div class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center">
                  <div class="w-px bg-gray-400"></div>
                </div>
                <svg *ngIf="audit.type !== 'Note'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor"
                     class="relative h-6 w-6 flex-none rounded-full bg-gray-50 ">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>
                <svg *ngIf="audit.type === 'Note'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                     stroke-width="1.5" stroke="currentColor"
                     class="relative my-4 h-6 w-6 flex-none rounded-full bg-gray-50 ">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                </svg>

                <ng-container *ngIf="audit.type !== 'Note'">
                  <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500 align-bottom mb-0"><span
                    class="font-poppins font-medium text-gray-900">{{ audit.user }}</span>
                    {{ audit.type | propAudits }}.</p>
                  <time datetime="2023-01-23T11:24"
                        class="flex-none py-0.5 text-xs leading-5 text-gray-500">{{ audit.date | date }}
                  </time>
                </ng-container>

                <ng-container *ngIf="audit.type === 'Note'">
                  <div class="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-300">
                    <div class="flex justify-between gap-x-4">
                      <div class="flex items-center gap-x-1 py-0.5 text-xs leading-6 text-gray-500"><span
                        class="font-poppins font-medium text-gray-900 align-middle">{{ audit.user }}</span> {{ audit.type === 'Note' ? 'commented' : 'updated details' }}
                      </div>
                      <time datetime="2023-01-23T15:56"
                            class="flex-none py-0.5 text-xs leading-5 text-gray-500">{{ audit.date | date }}
                      </time>

                    </div>
                    <p *ngIf="audit.type === 'Note'" class="text-sm leading-6 text-gray-500">{{ audit.message }}</p>
                  </div>


                  <div class="flex items-center">
                    <button type="button" (click)="removeAudit(audit)"
                            *ngIf="userName === audit.user && audit.type === 'Note'"
                            class="rounded-full bg-transparent text-cw-blue p-1  hover:bg-cw-blue-light hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                           stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>

                    </button>
                  </div>
                </ng-container>


              </ng-container>

            </li>
          </ul>

        </div>
      </div>

    </card-body>
  </app-cw-card>

  <aside *ngIf="propertyForm"
         class="sticky flex-col shrink-0 mr-5 ml-0 w-full tablet:w-96 order-first tablet:order-last top-[-45px] tablet:top-[155px] mt-0 tablet:mt-5">
    <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 mx-5 tablet:mx-0 mt-5 tablet:mt-0">
      <dl class="flex flex-wrap">
        <div class="flex-auto pl-6 pt-6">

          <h3 *ngIf="propertyDetails"
              class="mb-0 font-poppins">{{ propertyDetails.unit }} {{ propertyDetails.unitFirst }}</h3>
          <p class="font-poppins">{{ propertyDetails.unitSecond }}</p>
          <div class="bg-gray-50 grid grid-cols-2 ">
            <dt class="font-poppins text-sm font-medium leading-6 text-gray-900">Unit Type</dt>
            <dd
              class="font-poppins  text-sm leading-6 text-gray-700 col-span-1 mt-0">{{ propertyDetails.unitType }}
            </dd>
          </div>
          <div class="bg-gray-50 grid grid-cols-2 ">
            <dt class="font-poppins text-sm font-medium leading-6 text-gray-900">Car Park Space</dt>
            <dd
              class="font-poppins  text-sm leading-6 text-gray-700 col-span-1 mt-0">{{ propertyDetails.carParkSpace }}
            </dd>
          </div>
        </div>
        <div class="mt-6 w-full flex-col flex-none gap-y-2 border-t border-gray-900/5 px-6 pt-6 hidden tablet:flex"
             *ngIf="!propertyForm.disabled">
          <app-cw-secondary-button buttonText="Transfer Ownership" *ngIf="!propertyForm.disabled"
                                   (onClick)="transferOwnership()"></app-cw-secondary-button>
          <app-cw-secondary-button buttonText="Add Payment" *ngIf="!propertyForm.disabled"
                                   (onClick)="tabSelected = 'account'"></app-cw-secondary-button>
          <app-cw-secondary-button buttonText="Add Comment" *ngIf="!propertyForm.disabled"
                                   (onClick)="tabSelected = 'activity'"></app-cw-secondary-button>
          <app-cw-secondary-button buttonText="Add File" *ngIf="!propertyForm.disabled"
                                   (onClick)="tabSelected = 'files'; fileInput.nativeElement.focus()"></app-cw-secondary-button>
          <app-cw-secondary-button buttonText="Update Service Charge" *ngIf="!propertyForm.disabled"
                                   (onClick)="tabSelected = 'files'; serviceChargeSelect.nativeElement.focus()"></app-cw-secondary-button>
        </div>
      </dl>
      <div
        class="mt-6 flex flex-col justify-center justify-items-stretch border-t border-gray-900/5 gap-x-2 px-6 py-6 w-full">
        <p *ngIf="propertyForm && propertyForm.dirty" class="w-full text-center">Unsaved Changes</p>
        <div class="flex flex-row justify-items-stretch gap-x-2">
          <app-cw-secondary-button buttonText="Cancel"
                                   [isDisabled]=" !propertyForm.dirty" (onClick)="resetForm()"
                                   class="w-1/2"></app-cw-secondary-button>
          <app-cw-primary-button buttonText="{{ saving ? 'Saving...' : transferOwner ? 'Transfer Owner':'Save'}}"
                                 class="w-1/2"
                                 [isDisabled]=" !propertyForm.dirty || propertyForm.dirty && propertyForm.invalid || saving"
                                 (onClick)="saveChanges()"></app-cw-primary-button>
        </div>
        <app-banner *ngIf="messageBanner" [eventType]="messageBanner" [message]="messageText"></app-banner>


      </div>
    </div>

    <div class="hidden mt-5 px-1 tablet:flex flex-col ">

      <h3 class="w-full text-center font-poppins mb-0">Latest Activity</h3>

      <ul role="list" class="space-y-4  overflow-hidden h-auto my-2">
        <li class="relative flex gap-x-3 mt-0"
            *ngFor="let audit of (propertyAudits | reverse).slice(0, 4);  index as i;">
          <ng-container>
            <div class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center">
              <div class="w-px bg-gray-400"></div>
            </div>
            <svg *ngIf="audit.type !== 'Note'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke-width="1.5" stroke="currentColor"
                 class="relative h-6 w-6 flex-none rounded-full bg-cw-bg-gray ">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
            </svg>
            <svg *ngIf="audit.type === 'Note'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke-width="1.5" stroke="currentColor"
                 class="relative my-4 h-6 w-6 flex-none rounded-full bg-cw-bg-gray ">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
            </svg>

            <ng-container *ngIf="audit.type !== 'Note'">
              <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500 align-bottom mb-0"><span
                class="font-poppins font-medium text-gray-900">{{ audit.user }}</span>
                {{ audit.type | propAudits }}.</p>
              <time datetime="2023-01-23T11:24"
                    class="flex-none py-0.5 text-xs leading-5 text-gray-500">{{ audit.date | date }}
              </time>
            </ng-container>

            <ng-container *ngIf="audit.type === 'Note'">
              <div class="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-500">
                <div class="flex justify-between gap-x-4">
                  <div class="flex items-center gap-x-1 py-0.5 text-xs leading-6 text-gray-500"><span
                    class="font-poppins font-medium text-gray-900 align-middle">{{ audit.user }}</span> commented
                  </div>
                  <time datetime="2023-01-23T15:56"
                        class="flex-none py-0.5 text-xs leading-5 text-gray-500">{{ audit.date | date }}
                  </time>

                </div>
                <p *ngIf="audit.type === 'Note'" class="text-sm leading-6 text-gray-500">{{ audit.message }}</p>
              </div>

            </ng-container>


          </ng-container>

        </li>


      </ul>
    </div>

  </aside>
</div>


<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>
