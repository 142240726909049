import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UpdateServiceService } from './services/update/update-service.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {
    title = 'Century-Wharf-App';

    constructor(public updateService: UpdateServiceService) {
        this.updateService.check();
    }
}
