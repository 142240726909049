import { Component, OnInit } from '@angular/core';
import { ContractorsService } from '../../../services/contractors/contractors.service';
import { ContractorDetails } from '../../contractor-sign-up/contractor-sign-up.component';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkOrderEvents, WorkOrderStatus } from '../../../enums/work-orders';
import { WorkOrdersService } from '../../../services/work-orders/work-orders.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoModalComponent } from '../../../components/modals/info-modal/info-modal.component';
import { WorkOrder } from '../work-orders.component';
import { AuditService } from '../../../services/audit/audit.service';
import {
    AuthServiceService,
    UserGroups,
} from '../../../services/auth-service/auth-service.service';
import { MatDialog } from '@angular/material/dialog';
import { AppConfigService } from '../../../services/app-config/app-config.service';
import { BudgetHeadConst } from '../../../constants/budgets';
import { Schedule } from '../../../interfaces/config-interface';

@Component({
    selector: 'app-create-works-order',
    templateUrl: './create-works-order.component.html',
    styleUrls: ['./create-works-order.component.css'],
})
export class CreateWorksOrderComponent implements OnInit {
    risks = [
        'Demolition',
        'Working With Electricity',
        'Fire Alarm System',
        'Working At Height',
        'Hot Works',
        'Public Domain',
    ];
    approvedContractors: Partial<ContractorDetails>[] = [];
    leaseGroups: Schedule[] = [];
    expenseCategory: BudgetHeadConst[] = [];
    selectedLeaseGroups: string[] = [];
    groupValues: number[] = [];
    totalValue: string = '0';
    workOrderForm!: FormGroup;
    loading: boolean = true;
    authLimit: number = 0;
    submitting: boolean = false;
    isRetro: boolean = false;
    currentDate: Date = new Date();
    minPaidDate: Date = new Date(2023, 8, 1);
    invoice: File | undefined;
    userGroups: (string | number)[] = [];

    constructor(
        public contractorService: ContractorsService,
        public workOrderService: WorkOrdersService,
        public router: Router,
        public dialog: MatDialog,
        public auditService: AuditService,
        public auth: AuthServiceService,
        public route: ActivatedRoute,
        private activatedRoute: ActivatedRoute,
        public appConfig: AppConfigService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        try {
            this.userGroups = await this.auth.getGroups();
            this.expenseCategory = this.appConfig.config.budgetData.heads.sort(
                (a, b) => a.name.localeCompare(b.name)
            );
            this.leaseGroups = this.appConfig.config.budgetData.schedules;

            await this.initialiseForm();
            this.onValueChange();

            this.approvedContractors = (
                (await firstValueFrom(
                    await this.contractorService.getContractorList()
                )) || []
            )
                .filter((contractor) => contractor.status === 'verified')
                .sort((a, b) => a!.companyName!.localeCompare(b!.companyName!));

            this.authLimit = await this.auth.getAuthLimit();

            const orderNumberCopy = (await firstValueFrom(this.route.params))[
                'orderNumber'
            ];
            if (orderNumberCopy) {
                const workOrderCopy = await firstValueFrom(
                    await this.workOrderService.getOrder(orderNumberCopy)
                );
                await this.setValuesFromCopy(workOrderCopy);
            }
            this.loading = false;
        } catch (error: any) {
            this.loading = false;
        }
    }

    async setValuesFromCopy(workOrder: WorkOrder) {
        this.workOrderForm.patchValue({
            contractorId: this.approvedContractors.find(
                (contractor) =>
                    contractor.applicationId ===
                    workOrder.contractorId.applicationId
            ),
            expenseCategory: workOrder.expenseCategory,
            description: workOrder.description,
            risks: workOrder.risks,
        });

        this.selectedLeaseGroups = Object.keys(workOrder.leaseGroups);
        this.onSelectedLeaseGroupChange();
        for (let leaseGroupsKey in workOrder.leaseGroups) {
            this.getLeaseGroupsForms().controls[leaseGroupsKey].setValue(
                workOrder.leaseGroups[leaseGroupsKey]
            );
        }
    }

    async initialiseForm() {
        this.workOrderForm = new FormGroup({
            contractorId: new FormControl(
                { value: null, disabled: false },
                Validators.required
            ),
            expenseCategory: new FormControl(
                { value: null, disabled: false },
                Validators.required
            ),
            leaseGroups: new FormGroup({}),
            description: new FormControl(
                { value: null, disabled: false },
                Validators.required
            ),
            risks: new FormControl({ value: null, disabled: false }),
            ...(this.isRetro && {
                paidDate: new FormControl(
                    { value: null, disabled: false },
                    Validators.required
                ),
            }),
        });
    }

    getLeaseGroupsForms() {
        return this.workOrderForm.get('leaseGroups') as FormGroup;
    }

    onValueChange(): void {
        this.getLeaseGroupsForms().valueChanges.subscribe(() => {
            this.totalValue = (
                Object.values(this.getLeaseGroupsForms().value) as number[]
            )
                .reduce((a, b) => a + b, 0)
                .toFixed(2);
        });
    }

    onSelectedLeaseGroupChange() {
        this.selectedLeaseGroups.forEach((group) => {
            if (
                !Object.keys(this.getLeaseGroupsForms().controls).includes(
                    group
                )
            ) {
                this.getLeaseGroupsForms().addControl(
                    group,
                    new FormControl(null, Validators.required)
                );
            }
        });

        Object.keys(this.getLeaseGroupsForms().controls).forEach((control) => {
            if (!this.selectedLeaseGroups.includes(control)) {
                this.getLeaseGroupsForms().removeControl(control);
            }
        });
    }

    /**
     * Open info dialog
     * @param message
     */
    openInfoDialog = (message: string): void => {
        this.dialog.open(InfoModalComponent, {
            width: '500px',
            data: { message },
        });
    };

    async createWorkOrder() {
        this.submitting = true;
        const status: WorkOrderStatus =
            +this.totalValue > this.authLimit
                ? WorkOrderStatus.AwaitingAuth
                : WorkOrderStatus.Open;
        const payload: Partial<WorkOrder> = {
            ...this.workOrderForm.getRawValue(),
            status,
            dateCreated: new Date().toISOString(),
            audits: [
                await this.auditService.createWorkOrderAudit(
                    WorkOrderEvents.Created
                ),
            ],
        };

        try {
            const response = await firstValueFrom(
                await this.workOrderService.createWorkOrder(payload)
            );
            await this.openInfoDialog(response);
            await this.router.navigateByUrl('/home-page/work-orders');
        } catch (error) {
            this.openInfoDialog(JSON.stringify(error));
        } finally {
            this.submitting = false;
        }
    }

    scheduleDisplay = (code: string) =>
        this.leaseGroups.find((sched) => sched.code === code)?.name;

    contractorCompare(
        t1: Partial<ContractorDetails>,
        t2: Partial<ContractorDetails>
    ) {
        return t1?.applicationId === t2?.applicationId;
    }

    isCorrectType = (fileTypes: string[], file: File): boolean => {
        return fileTypes.includes(file.type);
    };

    async createRetroWorkOrder() {
        if (!this.invoice) return;

        this.submitting = true;

        const status: WorkOrderStatus = WorkOrderStatus.Paid;
        const { paidDate, ...formVals } = this.workOrderForm.getRawValue();
        const payload: Partial<WorkOrder> = {
            ...formVals,
            status,
            dateCreated: new Date().toISOString(),
            audits: [
                await this.auditService.createWorkOrderAudit(
                    WorkOrderEvents.RetroCreated
                ),
                await this.auditService.createWorkOrderAudit(
                    WorkOrderEvents.InvoiceUploaded,
                    paidDate
                ),
                await this.auditService.createWorkOrderAudit(
                    WorkOrderEvents.InvoiceApproved,
                    paidDate
                ),
                await this.auditService.createWorkOrderAudit(
                    WorkOrderEvents.InvoicePaid,
                    paidDate
                ),
            ],
        };

        try {
            const response: string = await firstValueFrom(
                this.workOrderService.createWorkOrder(payload)
            );
            const orderNumber: string = response.split('#')[1];
            await this.uploadFile(this.invoice, orderNumber).catch(() =>
                console.error('Invoice failed to upload')
            );

            this.openInfoDialog(response);
            await this.router.navigateByUrl('/home-page/work-orders');
        } catch (error) {
            this.openInfoDialog(JSON.stringify(error));
        } finally {
            this.submitting = false;
        }
    }

    uploadFile = async (file: File, orderNumber: string): Promise<any> => {
        const url = await firstValueFrom(
            this.workOrderService.getPresignedUrl(
                orderNumber + '/invoice.pdf',
                'Put'
            )
        );

        return await lastValueFrom(
            this.workOrderService.fileUpload(url, '', file)
        );
    };

    toggleRetro = () => {
        this.loading = true;

        if (this.isRetro) {
            this.workOrderForm.addControl(
                'paidDate',
                new FormControl(
                    { value: null, disabled: false },
                    Validators.required
                )
            );
        } else {
            this.workOrderForm.removeControl('paidDate');
        }

        this.loading = false;
    };
    protected readonly UserGroups = UserGroups;
}
