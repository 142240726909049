import { Component, OnDestroy, OnInit } from '@angular/core';
import { BudgetsService } from '../../../../services/budgets/budgets.service';
import {
    breakdown,
    Budget,
    BudgetHeadValues,
} from '../../../../interfaces/budget-interfaces';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { InfoModalComponent } from '../../../../components/modals/info-modal/info-modal.component';
import { PropertyFilter } from '../../../../enums/property';
import { PropertyDetailsService } from '../../../../services/property-details/property-details.service';
import { BudgetModalComponent } from '../../../../components/modals/budget-modal/budget-modal.component';
import { getDate, getMonth } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { AppConfigService } from '../../../../services/app-config/app-config.service';
import { BudgetHeadConst } from '../../../../constants/budgets';
import { Schedule } from '../../../../interfaces/config-interface';

@Component({
    selector: 'app-edit-budget',
    templateUrl: './edit-budget.component.html',
    styleUrls: ['./edit-budget.component.css'],
})
export class EditBudgetComponent implements OnInit, OnDestroy {
    newBudgetForm!: FormGroup;
    budgetYear!: string;
    nextBudgetYear!: string;
    draftBudget!: Budget[];
    budgetValue = 0;
    reserveValue = 0;
    totalValue = 0;
    budgetHeads: BudgetHeadConst[] = [];
    reserveHeads: Schedule[] = [];
    readOnlyMode: boolean = false;
    tabSelected: string = 'heads';
    saving: boolean = false;
    destroy$ = new Subject();
    loading: boolean = true;
    messageText!: string;
    messageBanner: 'SUCCESS' | 'ERROR' | undefined;
    unitCourts!: string[];
    unitNumbers!: string[];
    searchForm = new FormGroup({
        unitFirst: new FormControl(),
        unit: new FormControl(),
    });

    constructor(
        public budgetService: BudgetsService,
        public propDetailsService: PropertyDetailsService,
        public dialog: MatDialog,
        public appConfig: AppConfigService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        try {
            this.budgetHeads = [...this.appConfig.config.budgetData.heads].sort(
                (a, b) => a.name.localeCompare(b.name)
            );
            this.reserveHeads = this.appConfig.config.budgetData.schedules;

            this.budgetYear = this.appConfig.config.budgetData.currentYear;

            this.nextBudgetYear = this.budgetYear
                .split('-')
                .map((year) => +year + 1)
                .join('-');

            this.draftBudget =
                (await firstValueFrom(
                    await this.budgetService.getSelectedBudgets([
                        this.nextBudgetYear,
                    ])
                )) || [];

            this.unitCourts = await this.propDetailsService.getUnitFilters(
                PropertyFilter.UnitFirst
            );

            this.createForm();
            this.subscribeToCourt();
        } catch (error: any) {
            console.error(error);
        } finally {
            this.loading = false;
        }
    }

    /** Subscription to unitCourt value changes */
    subscribeToCourt(): void {
        this.searchForm.controls[
            PropertyFilter.UnitFirst
        ].valueChanges.subscribe(async (value) => {
            this.unitNumbers = await this.propDetailsService.getUnitNumbers(
                PropertyFilter.UnitFirst,
                value
            );
            this.searchForm.controls[PropertyFilter.UnitNumber].setValue(null, {
                onlySelf: true,
                emitEvent: false,
            });
        });
    }

    createForm() {
        this.newBudgetForm = new FormGroup({});
        this.budgetHeads.forEach((head) => {
            if (!head?.subCats) {
                this.newBudgetForm.addControl(
                    head.code,
                    new FormControl({
                        value:
                            this.draftBudget[0]?.budgetValues?.find(
                                (value) => value.code === head.code
                            )?.value || null,
                        disabled: this.readOnlyMode,
                    })
                );
            } else {
                head.subCats.forEach((subCat) => {
                    this.newBudgetForm.addControl(
                        subCat.code,
                        new FormControl({
                            value:
                                this.draftBudget[0]?.budgetValues?.find(
                                    (value) => value.code === subCat.code
                                )?.value || null,
                            disabled: this.readOnlyMode,
                        })
                    );
                });
            }
        });

        this.reserveHeads.forEach((head) => {
            this.newBudgetForm.addControl(
                head.code,
                new FormControl({
                    value:
                        this.draftBudget[0]?.budgetValues?.find(
                            (value) => value.code === head.code
                        )?.value || null,
                    disabled: this.readOnlyMode,
                })
            );
        });

        this.updateTotal();
        this.calcTotalChanges();
    }

    ngOnDestroy() {
        this.destroy$.next({});
        this.destroy$.complete();
    }

    calcTotalChanges() {
        this.newBudgetForm.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.updateTotal();
            });
    }

    updateTotal() {
        this.budgetValue = Object.keys(this.newBudgetForm.value)
            .filter((headCode) => headCode.includes('BH'))
            .map((headCode) => this.newBudgetForm.get(headCode)?.value)
            .reduce((a, b) => a + b || 0, 0);

        this.reserveValue = Object.keys(this.newBudgetForm.value)
            .filter((headCode) => headCode.includes('SDH'))
            .map((headCode) => this.newBudgetForm.get(headCode)?.value)
            .reduce((a, b) => a + b || 0, 0);

        this.totalValue = this.budgetValue + this.reserveValue;
    }

    async updateBudget() {
        this.saving = true;
        const payloadArray: BudgetHeadValues[] = Object.entries(
            this.newBudgetForm.value
        ).map(([key, value]) => {
            return { code: key, value };
        }) as unknown as BudgetHeadValues[];

        const payload: Budget = {
            site: this.appConfig.selectedSite,
            budgetYear: this.nextBudgetYear,
            budgetValues: payloadArray,
        };

        try {
            await firstValueFrom(
                await this.budgetService.updateBudget(payload)
            );
            this.showMessageBanner('Budget has been Updated', 'SUCCESS');
            this.newBudgetForm.markAsPristine();
        } catch (error) {
            this.showMessageBanner(
                'Cannot connect to Server. Changes have not been saved.',
                'ERROR'
            );
        } finally {
            this.saving = false;
        }
    }

    /**
     * Open info dialog
     * @param message
     */
    openInfoDialog = (message: string): void => {
        this.dialog.open(InfoModalComponent, {
            width: '500px',
            data: { message },
        });
    };

    /**
     * Show Message banner
     *
     * @param text
     * @param type
     */
    showMessageBanner = (text: string, type: 'SUCCESS' | 'ERROR'): void => {
        this.messageText = text;
        this.messageBanner = type;
        setTimeout(() => (this.messageBanner = undefined), 3000);
    };

    calculateCatValue(subCats: any[] | undefined): string {
        return subCats
            ?.map((cat) => this.newBudgetForm.get(cat.code)?.value)
            .reduce((a, b) => a + b, 0)
            .toString();
    }

    async getBreakdown() {
        const unitFull = (await this.propDetailsService.getSearchCriteria(
            this.searchForm.controls[PropertyFilter.UnitNumber].value,
            PropertyFilter.UnitFirst,
            this.searchForm.controls[PropertyFilter.UnitFirst].value
        )) as string;
        const breakdownData: breakdown[] = await firstValueFrom(
            this.budgetService.getUnitBreakdown(this.nextBudgetYear, unitFull)
        );
        this.openBudgetModal(breakdownData[0]);
    }

    openBudgetModal = (budgetData: breakdown) => {
        this.dialog.open(BudgetModalComponent, {
            width: '700px',
            data: { budgetData },
        });
    };

    getServiceCharge = async () => {
        const unitFull = (await this.propDetailsService.getSearchCriteria(
            this.searchForm.controls[PropertyFilter.UnitNumber].value,
            PropertyFilter.UnitFirst,
            this.searchForm.controls[PropertyFilter.UnitFirst].value
        )) as string;

        const pdf = await firstValueFrom(
            this.budgetService.generateServiceCharge(
                this.nextBudgetYear,
                unitFull
            )
        );
        const url = `data:application/pdf;base64,${pdf}`;

        const link = document.createElement('a');
        link.href = url;
        link.download = 'Test';
        link.click();
    };
}
