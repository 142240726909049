import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { environment } from '../../../environments/environment';
import { PasswordModalComponent } from '../../components/modals/password-modal/password-modal.component';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';
import { ForgotPasswordModalComponent } from '../../components/modals/forgot-password-modal/forgot-password-modal.component';
import { NGXLogger } from 'ngx-logger';
import { InfoModalComponent } from '../../components/modals/info-modal/info-modal.component';
import * as Sentry from '@sentry/angular-ivy';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export enum UserTypes {
    Staff = 'Staff',
    Contractor = 'Contractor',
    Leaseholder = 'Leaseholder',
}

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.css'],
})
export class LandingPageComponent implements OnInit {
    UserTypes = UserTypes;
    loginForm: FormGroup = new FormGroup({
        email: new FormControl(null, Validators.required),
        password: new FormControl(null, Validators.required),
    });
    pVis: boolean = false;

    constructor(
        public router: Router,
        public dialog: MatDialog,
        public auth: AuthServiceService,
        public logger: NGXLogger
    ) {}

    async ngOnInit(): Promise<void> {}

    async login() {
        const { email, password } = this.loginForm.getRawValue();

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        const poolData = {
            UserPoolId: environment.cognitoUserPoolId,
            ClientId: environment.cognitoAppClientId,
        };

        const userPool = new CognitoUserPool(poolData);
        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (session, userConfirmationNecessary) => {
                this.navigateOnSuccess();
            },
            onFailure: (error) => {
                this.dialog.open(InfoModalComponent, {
                    width: '500px',
                    data: {
                        message: 'Incorrect E-mail or Password',
                        title: 'Login Unsuccessful',
                        fail: true,
                    },
                });
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                delete userAttributes.email_verified;
                delete userAttributes.email;
                const dialogRef = this.dialog.open(PasswordModalComponent, {
                    width: '500px',
                    data: {},
                });

                dialogRef.afterClosed().subscribe(async (result) => {
                    if (result) {
                        cognitoUser.completeNewPasswordChallenge(
                            result,
                            userAttributes,
                            {
                                onSuccess: (
                                    session,
                                    userConfirmationNecessary
                                ) => {
                                    this.navigateOnSuccess();
                                },
                                onFailure: (error) => {
                                    alert(error.message);
                                },
                            }
                        );
                    }
                });
            },
        });
    }

    navigateOnSuccess = async () => {
        const [userName, email] = await this.auth.getUser();
        Sentry.setUser({
            username: userName,
            email,
        });
        this.logger.log(
            `${userName}: Logged in. Version: ${environment.appVersion}`
        );
        const groups = await this.auth.getGroups();
        if (!groups) {
            alert('No Groups associated with User');
            return;
        }
        const profile = await this.auth.getProfile();

        if (groups.includes('Staff'))
            await this.router.navigate(['home-page/dashboard-page']);
        if (groups.includes('Contractor'))
            await this.router.navigate([`/contractor/${profile}`]);
        if (groups.includes('Leaseholder'))
            await this.router.navigate([`/account`]);
    };

    forgottenPassword = async () => {
        const dialogRef = this.dialog.open(ForgotPasswordModalComponent, {
            width: '500px',
            data: {},
        });
    };
}
