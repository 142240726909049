import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
} from 'amazon-cognito-identity-js';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { AuthServiceService } from '../../../services/auth-service/auth-service.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-forgot-password-modal',
    templateUrl: './forgot-password-modal.component.html',
    styleUrls: ['./forgot-password-modal.component.css'],
})
export class ForgotPasswordModalComponent implements OnInit {
    email!: string;
    enterEmail: boolean = true;
    passwordForm: FormGroup = new FormGroup<any>({
        newPassword: new FormControl('', [
            Validators.required,
            Validators.minLength(8),
            this.regexValidator(/\d/, { oneNumber: true }),
            this.regexValidator(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, {
                oneSpec: true,
            }),
            this.regexValidator(/[A-Z]/, { oneUpper: true }),
            this.regexValidator(/[a-z]/, { oneLower: true }),
        ]),
        confirmPassword: new FormControl('', [
            Validators.required,
            Validators.minLength(8),
        ]),
        verificationCode: new FormControl(null, Validators.required),
    });
    emailForm: FormGroup = new FormGroup({
        email: new FormControl(null, [Validators.required, Validators.email]),
    });

    constructor(
        public auth: AuthServiceService,
        public router: Router,
        public dialog: MatDialogRef<ForgotPasswordModalComponent>
    ) {}

    ngOnInit(): void {
        this.passwordForm.updateValueAndValidity();
    }

    regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return error;
            }
            const valid: boolean = regex.test(control.value);
            return valid ? null : error;
        };
    }

    getCognitoUser = () => {
        const { email } = this.emailForm.getRawValue();

        const poolData = {
            UserPoolId: environment.cognitoUserPoolId,
            ClientId: environment.cognitoAppClientId,
        };
        const userPool = new CognitoUserPool(poolData);
        const userData = {
            Username: email,
            Pool: userPool,
        };
        return new CognitoUser(userData);
    };

    submitEmail = () => {
        const cognitoUser = this.getCognitoUser();
        cognitoUser.forgotPassword({
            onSuccess: (data) => {
                this.enterEmail = false;
            },
            onFailure: (error) => {
                console.error(error);
            },
        });
    };

    createNew = () => {
        const cognitoUser = this.getCognitoUser();
        const { email } = this.emailForm.getRawValue();
        const { newPassword, verificationCode } =
            this.passwordForm.getRawValue();

        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess: (data) => {
                const authDetails = new AuthenticationDetails({
                    Username: email,
                    Password: newPassword,
                });
                cognitoUser.authenticateUser(authDetails, {
                    onSuccess: (session, userConfirmationNecessary) => {
                        this.navigateOnSuccess();
                    },
                    onFailure: (error) => {
                        alert(error.message);
                    },
                });
            },
            onFailure: (error) => {
                console.error(error);
            },
        });
    };

    navigateOnSuccess = async () => {
        const groups = await this.auth.getGroups();
        const profile = await this.auth.getProfile();

        if (groups.includes('Staff'))
            await this.router.navigate(['home-page/dashboard-page']);
        if (groups.includes('Contractor'))
            await this.router.navigate([`/contractor/${profile}`]);
        this.dialog.close();
    };
}
