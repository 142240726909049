<div [formGroup]="formGroup"
     class="relative my-4">
  <label for="{{ controlName }}"
         class="absolute z-50 -top-2 left-2 inline-block bg-slate-50 px-1 text-xs font-medium text-gray-900">{{ isRequired() ? label + '*' : label }}</label>
  <select #selectElement id="{{ controlName }}" name="{{ controlName }}" formControlName="{{ controlName }}"

          [ngClass]="showError && formGroup.controls[controlName].touched && formGroup.controls[controlName].errors ? 'ring-red-300' : 'ring-gray-300' "
          class="block font-poppins h-12 bg-slate-50 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cw-blue sm:text-sm sm:leading-6">
    <option *ngIf="emptyOption"></option>
    <option *ngFor="let option of options"
            [value]="option">{{ startCase([true, false].includes(option) ? option === true ? 'Yes' : 'No' : option) }}
    </option>

  </select>
  <div *ngIf="showError && formGroup.controls[controlName].touched && formGroup.controls[controlName].errors"
       class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
    <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
      <path fill-rule="evenodd"
            d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 10 5Zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
            clip-rule="evenodd" />
    </svg>
  </div>

</div>
<p *ngIf="showError && formGroup.controls[controlName].touched && formGroup.controls[controlName].errors?.['required']"
   class="text-xs text-red-600 text-center"
   id="email-error">
  This field is required</p>
