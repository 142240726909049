<app-page-toolbar [pageTitle]="'Reports'"></app-page-toolbar>

<div class="mx-auto flex w-full flex-col max-w-7xl items-stretch">
  <app-cw-card [title]="'Generate Report'" [showFooter]="false" class="mt-5">
    <card-icon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
      </svg>
    </card-icon>
    <card-body>
      <p>Please select a report type:</p>

      <app-cw-select [label]="'Report Type'" [formGroup]="reportForm" [controlName]="'type'" [emptyOption]="true"
                     [options]="['Boiler Servicing', 'View Properties', 'Sub Let']"></app-cw-select>

    </card-body>
  </app-cw-card>

  <!-- Boiler Servicing -->

  <app-cw-card *ngIf="reportType === 'Boiler Servicing' && boilerForm" [title]="'Boiler Servicing'" [showFooter]="true">
    <card-icon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
      </svg>
    </card-icon>
    <card-body>

      <div *ngIf="boilerForm" class="" [formGroup]="boilerForm">
        <p>Please select Units to view:</p>
        <button (click)="toggleAll('court')" class="w-[200px] font-poppins bg-cw-blue text-slate-50 rounded-lg">Select
          all / none
        </button>
        <div *ngFor="let court of unitCourts; index as i" class="relative flex items-center space-y-1 ml-4">
          <div class="flex h-6 items-center justify-center">
            <input id="units{{i}}" aria-describedby="unit-description" name="units" type="checkbox"
                   formControlName="{{court}}"
                   class="h-4 w-4 rounded border-gray-300 text-cw-blue focus:ring-cw-blue leading-6">
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="units{{i}}" class="font-medium text-gray-900 leading-6">{{ court }}</label>
            <span id="unit-description" class="text-gray-500"><span class="sr-only">{{ court }}}</span></span>
          </div>
        </div>

        <fieldset class="mt-4 mb-4">
          <legend class="text-gray-900 mb-3">Select units:</legend>
          <div class="relative flex flex-col items-start space-y-1 ml-4">
            <div class="flex items-center">
              <input id="expired" name="reportType" type="radio" [value]="'overdue'" formControlName="reportType"
                     class="h-4 w-4 border-gray-300 text-cw-blue focus:ring-cw-blue">
              <label for="expired" class="ml-3 block text-sm font-medium leading-6 text-gray-900">Expired units</label>
            </div>
            <div class="flex items-center">
              <input id="dueTo" name="reportType" type="radio"
                     class="h-4 w-4 border-gray-300 text-cw-blue focus:ring-cw-blue" [value]="'month'"
                     formControlName="reportType">
              <label for="dueTo" class="ml-3 block text-sm font-medium leading-6 text-gray-900">Units due to
                expire in (month)</label>
            </div>
          </div>
        </fieldset>

        <ng-container *ngIf="boilerForm.controls['reportType'].value === 'month'">
          <button (click)="toggleAll('month')" class="w-[200px] font-poppins bg-cw-blue text-slate-50 rounded-lg">Select
            all / none
          </button>
          <div *ngFor="let month of monthsArray; index as i" class="relative flex items-center space-y-1 ml-8">
            <div class="flex h-6 items-center justify-center">
              <input id="months{{i}}" aria-describedby="unit-description" name="units" type="checkbox"
                     formControlName="{{month}}"
                     class="h-4 w-4 rounded border-gray-300 text-cw-blue focus:ring-cw-blue leading-6">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="months{{i}}" class="font-medium text-gray-900 leading-6">{{
                  month | date: 'MMMM y'
                }}</label>
            </div>
          </div>
        </ng-container>

      </div>

    </card-body>
    <card-footer>
      <app-cw-primary-button
        (onClick)="generateBoilerReport()"
        [isDisabled]="!boilerValid()
        "
        [buttonText]="'Generate Report'"
      >
      </app-cw-primary-button>
    </card-footer>


  </app-cw-card>

  <!-- Boiler Servicing -->

  <!-- View Properties -->

  <app-cw-card *ngIf="reportType === 'View Properties' && boilerForm" [title]="'View Properties'" [showFooter]="true">
    <card-icon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
      </svg>
    </card-icon>
    <card-body [formGroup]="boilerForm">
      <fieldset class="mt-4 mb-4">
        <legend class="text-gray-900 mb-3">Select units:</legend>
        <div class="relative flex flex-col items-start space-y-1 ml-4">
          <div class="flex items-center">
            <input id="viewAll" name="reportType" type="radio" [value]="'viewAll'" formControlName="reportType"
                   class="h-4 w-4 border-gray-300 text-cw-blue focus:ring-cw-blue">
            <label for="viewAll" class="ml-3 block text-sm font-medium leading-6 text-gray-900">View All
              Properties</label>
          </div>
          <div class="flex items-center">
            <input id="court" name="reportType" type="radio"
                   class="h-4 w-4 border-gray-300 text-cw-blue focus:ring-cw-blue" [value]="'court'"
                   formControlName="reportType">
            <label for="court" class="ml-3 block text-sm font-medium leading-6 text-gray-900">View by Court</label>
          </div>
        </div>
      </fieldset>

      <ng-container *ngIf="boilerForm.controls['reportType'].value === 'court'">
        <button (click)="toggleAll('court')" class="w-[200px] font-poppins bg-cw-blue text-slate-50 rounded-lg">Select
          all / none
        </button>
        <div *ngFor="let court of unitCourts; index as i" class="relative flex items-center space-y-1 ml-8">
          <div class="flex h-6 items-center justify-center">
            <input id="units{{i}}" aria-describedby="unit-description" name="units" type="checkbox"
                   formControlName="{{court}}"
                   class="h-4 w-4 rounded border-gray-300 text-cw-blue focus:ring-cw-blue leading-6">
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="units{{i}}" class="font-medium text-gray-900 leading-6">{{ court }}</label>
          </div>
        </div>
      </ng-container>

    </card-body>
    <card-footer>
      <app-cw-primary-button
        (onClick)="generateViewReport()"
        [isDisabled]="!viewValid()"
        [buttonText]="'Generate Report'"
      >
        Generate Report
      </app-cw-primary-button>
    </card-footer>

  </app-cw-card>

  <!-- View Properties -->

  <!-- Sub Let -->

  <app-cw-card *ngIf="reportType === 'Sub Let' && boilerForm" [title]="'Sub Let'" [showFooter]="true">
    <card-icon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
      </svg>
    </card-icon>
    <card-body>

      <div *ngIf="boilerForm" class="" [formGroup]="boilerForm">
        <p>Please select Units to view:</p>
        <button (click)="toggleAll('court')" class="w-[200px] font-poppins bg-cw-blue text-slate-50 rounded-lg">Select
          all / none
        </button>
        <div *ngFor="let court of unitCourts; index as i" class="relative flex items-center space-y-1 ml-4">
          <div class="flex h-6 items-center justify-center">
            <input id="units{{i}}" aria-describedby="unit-description" name="units" type="checkbox"
                   formControlName="{{court}}"
                   class="h-4 w-4 rounded border-gray-300 text-cw-blue focus:ring-cw-blue leading-6">
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="units{{i}}" class="font-medium text-gray-900 leading-6">{{ court }}</label>
          </div>
        </div>

        <fieldset class="mt-4 mb-4">
          <legend class="text-gray-900 mb-3">Select units:</legend>
          <div class="relative flex flex-col items-start space-y-1 ml-4">
            <div class="flex items-center">
              <input id="subLetExpired" name="reportType" type="radio" [value]="'overdue'" formControlName="reportType"
                     class="h-4 w-4 border-gray-300 text-cw-blue focus:ring-cw-blue">
              <label for="subLetExpired" class="ml-3 block text-sm font-medium leading-6 text-gray-900">Expired
                units</label>
            </div>
            <div class="flex items-center">
              <input id="SubLetDueTo" name="reportType" type="radio"
                     class="h-4 w-4 border-gray-300 text-cw-blue focus:ring-cw-blue" [value]="'month'"
                     formControlName="reportType">
              <label for="SubLetDueTo" class="ml-3 block text-sm font-medium leading-6 text-gray-900">Units due to
                expire in (month)</label>
            </div>
          </div>
        </fieldset>

        <ng-container *ngIf="boilerForm.controls['reportType'].value === 'month'">
          <button (click)="toggleAll('month')" class="w-[200px] font-poppins bg-cw-blue text-slate-50 rounded-lg">Select
            all / none
          </button>
          <div *ngFor="let month of monthsArray; index as i" class="relative flex items-center space-y-1 ml-8">
            <div class="flex h-6 items-center justify-center">
              <input id="months{{i}}" aria-describedby="unit-description" name="units" type="checkbox"
                     formControlName="{{month}}"
                     class="h-4 w-4 rounded border-gray-300 text-cw-blue focus:ring-cw-blue leading-6">
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="months{{i}}" class="font-medium text-gray-900 leading-6">{{
                  month | date: 'MMMM y'
                }}</label>
            </div>
          </div>
        </ng-container>

      </div>

    </card-body>
    <card-footer>
      <app-cw-primary-button
        (onClick)="generateSubLetReport()"
        [isDisabled]="!subLetValid()
        "
        [buttonText]="'Generate Report'"
      >
      </app-cw-primary-button>
    </card-footer>


  </app-cw-card>

  <!-- Sub Let -->

</div>

<footer>
  <mat-card id="lastUpdated">
    <button
      (click)="propertyService.getReportData(true)"
      [disabled]="propertyService.updating"
      id="refreshButton"
      mat-icon-button
    >
      <mat-icon>refresh</mat-icon>
    </button>
    <mat-label slot="end"
    >Data last Updated:
      {{
        propertyService.updating
          ? 'Updating...'
          : (propertyService.lastUpdated
            | date: 'dd-MM-yyyy, h:mm:ss a')
      }}
    </mat-label
    >
  </mat-card>
</footer>


<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>
