import { Component, Input } from '@angular/core';
import { startCase } from 'lodash';
import { SectionConfig } from '../../../pages/property-details-page/property-result/property-result.component';
import { FormGroup } from '@angular/forms';
import { PropertyDetail } from '../../../interfaces/property';
import { countries } from 'countries-list';

@Component({
    selector: 'app-form-section',
    templateUrl: './form-section.component.html',
    styleUrls: ['./form-section.component.css'],
})
export class FormSectionComponent {
    @Input() section!: SectionConfig;
    @Input() form!: FormGroup;
    @Input() propertyDetails!: PropertyDetail;

    /**
     * * Calculate indexes for object subsection
     * @param section
     */
    calcSubSectionIndexes = (section: SectionConfig): number[] => {
        let subSection = section.headerDetails.subSections;
        let indexes: number[] = [];
        subSection?.forEach((subSection) => {
            indexes.push(
                section.data.findIndex(
                    (element) => element.subSection === subSection + '.'
                )
            );
        });
        return indexes;
    };

    getOptions(val: string | undefined) {
        switch (val) {
            case 'countries':
                return this.countries;
            default:
                return [true, false];
        }
    }

    protected readonly startCase = startCase;
    protected readonly countries = Object.values(countries)
        .map((value) => value.name)
        .sort();
}
