import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-cw-text',
    templateUrl: './cw-text.component.html',
    styleUrls: ['./cw-text.component.css'],
})
export class CwTextComponent {
    @Input() controlName: string = '';
    @Input() label: string = '';
    @Input() formGroup!: FormGroup;
    @Input() enterKey: boolean = false;
    @Input() showError: boolean = false;
    @Output() enterKeyed = new EventEmitter();

    onEnter() {
        if (this.enterKey) this.enterKeyed.emit();
    }

    isRequired() {
        const control = this.formGroup.get(this.controlName);
        if (!control || !control.validator) return false;

        const validator = control.validator({} as AbstractControl);

        return validator && validator['required'];
    }
}
