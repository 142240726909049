<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="relative transform overflow-hidden rounded-lg bg-slate-50 px-4 pb-4 pt-5 text-left shadow-xl sm:my-8 w-full max-w-lg sm:p-6">
        <div class="">
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Forgotten Password</h3>
            <div class="mt-2 flex flex-col justify-center items-center">
              <div *ngIf="enterEmail" class="w-full">
                <p class="text-sm text-gray-500">Please Enter your Email Address</p>
                <br />
                <app-cw-text [formGroup]="emailForm" [controlName]="'email'" [label]="'E-mail'"
                             [showError]="true"></app-cw-text>

              </div>

              <div *ngIf="!enterEmail && passwordForm" [formGroup]="passwordForm">
                <mat-label
                >A Verification Code has been sent to your E-Mail Address
                </mat-label
                >
                <br />
                <app-cw-text [controlName]="'verificationCode'" [label]="'Verification Code'"
                             [formGroup]="passwordForm" />
                <p>Please Create a new Password</p>

                <div [formGroup]="passwordForm"
                     class="relative my-4">
                  <label for="newpassword"
                         class="absolute -top-2 left-2 inline-block bg-slate-50 px-1 text-xs font-medium ">New
                    Password*</label>
                  <input #passwordInput type="password" id="newpassword" name="newpassword"
                         formControlName="newPassword"
                         class="block font-poppins h-12 bg-slate-50 w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 text-lg focus:ring-2 focus:ring-inset focus:ring-cw-blue sm:text-sm sm:leading-6">

                  <span (click)="passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password'"
                        class="pointer-events-auto cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3 z-50 bg-transparent">
            <svg *ngIf="passwordInput.type === 'password'" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="size-6 bg-slate-50">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
            <svg *ngIf="passwordInput.type === 'text'" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="size-6  bg-slate-50">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>


          </span>
                </div>

                <div [formGroup]="passwordForm"
                     class="relative my-4">
                  <label for="confirmpassword"
                         class="absolute -top-2 left-2 inline-block bg-slate-50 px-1 text-xs font-medium ">Confirm
                    Password*</label>
                  <input #passwordInputConfirm type="password" id="confirmpassword" name="confirmpassword"
                         formControlName="confirmPassword"
                         class="block font-poppins h-12 bg-slate-50 w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 text-lg focus:ring-2 focus:ring-inset focus:ring-cw-blue sm:text-sm sm:leading-6">

                  <span
                    (click)="passwordInputConfirm.type = passwordInputConfirm.type === 'password' ? 'text' : 'password'"
                    class="pointer-events-auto cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3 z-50 bg-transparent">
            <svg *ngIf="passwordInputConfirm.type === 'password'" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="size-6  bg-slate-50">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
            <svg *ngIf="passwordInputConfirm.type === 'text'" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="size-6  bg-slate-50">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>


          </span>
                </div>


              </div>
              <ul *ngIf="!enterEmail" class="w-3/4">
                <li [ngClass]="passwordForm.controls['verificationCode'].hasError('required') ? 'invalid' : 'valid'"
                    class="text-left">
                  Verification Code
                </li>
                <li
                  [ngClass]="passwordForm.controls['confirmPassword'].value.length > 0 && (passwordForm.controls['confirmPassword'].value === passwordForm.controls['newPassword'].value) ? 'valid' : 'invalid'"
                  class="text-left">
                  Passwords must match
                </li>
                <li [ngClass]="passwordForm.controls['newPassword'].hasError('oneNumber') ? 'invalid' : 'valid'"
                    class="text-left">At
                  least one number
                </li>
                <li [ngClass]="passwordForm.controls['newPassword'].hasError('oneSpec') ? 'invalid' : 'valid'"
                    class="text-left">
                  At least one Special Character
                </li>
                <li [ngClass]="passwordForm.controls['newPassword'].hasError('oneUpper') ? 'invalid' : 'valid'"
                    class="text-left">
                  At least one Uppercase Character
                </li>
                <li [ngClass]="passwordForm.controls['newPassword'].hasError('oneLower') ? 'invalid' : 'valid'"
                    class="text-left">
                  At least one Lowercase Character
                </li>
                <li
                  [ngClass]="passwordForm.controls['newPassword'].hasError('required') || passwordForm.controls['newPassword'].hasError('minlength') ? 'invalid' : 'valid'"
                  class="text-left">
                  Minimum
                  Length 8
                </li>
                <br />
              </ul>
            </div>
          </div>
        </div>
        <div class="mt-5 gap-3 flex flex-row justify-center items-stretch">
          <app-cw-secondary-button [mat-dialog-close]="null" [buttonText]="'Cancel'"
                                   class="w-1/2"></app-cw-secondary-button>
          <app-cw-primary-button *ngIf="enterEmail" (onClick)="submitEmail()" class="w-1/2"
                                 [isDisabled]="emailForm.invalid"
                                 [buttonText]="'Submit'"></app-cw-primary-button>
          <app-cw-primary-button *ngIf="!enterEmail" [buttonText]="'Reset'" (onClick)="createNew()" class="w-1/2"
                                 [isDisabled]="passwordForm.invalid || (passwordForm.controls['confirmPassword'].value !== passwordForm.controls['newPassword'].value)"></app-cw-primary-button>

        </div>
      </div>
    </div>
  </div>
</div>

