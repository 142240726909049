import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BudgetsService } from '../../../services/budgets/budgets.service';
import { Budget } from '../../../interfaces/budget-interfaces';
import { PropertyDetailsService } from '../../../services/property-details/property-details.service';
import { Transaction } from '../../../interfaces/property';
import { formatDate } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { AuthServiceService } from 'src/app/services/auth-service/auth-service.service';
import { AuditTypes } from 'src/app/services/audit/audit.service';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';
import { BudgetUtilities } from 'src/app/utilities/budget-utilities';

@Component({
    selector: 'app-income',
    templateUrl: './income.component.html',
    styleUrls: ['./income.component.css'],
})
export class IncomeComponent implements OnInit {
    loading: boolean = true;
    updating = true;
    totalValue = 0;
    budgetYears: string[] = [];
    currentBudgetYear!: string;
    nextBudgetYear!: string;
    selectedYears: string = '';
    selectedBudgets!: Budget[];
    allUnits: Transaction[] = [];
    allBoiler: Transaction[] = [];
    totalRec!: string;
    serviceChargeReceived!: string;
    otherRec!: string;
    totalBoiler!: string;
    totalService!: string;
    totalExp!: string;
    totalOut!: string;
    paymentButtons: boolean[] = [];
    paymentArray: Transaction[] = [];
    userGroups!: (string | number)[];
    messageBanner: 'SUCCESS' | 'ERROR' | undefined;
    messageText: string = '';

    paymentForm: FormGroup = new FormGroup({
        date: new FormControl(
            {
                value: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
                disabled: false,
            },
            { validators: [Validators.required] }
        ),
        description: new FormControl(
            { value: '', disabled: false },
            { validators: [Validators.required] }
        ),
        amount: new FormControl(
            { value: null, disabled: false },
            { validators: [Validators.required] }
        ),
    });

    constructor(
        public budgetService: BudgetsService,
        public propertyDetails: PropertyDetailsService,
        public dialog: MatDialog,
        public propDetailsService: PropertyDetailsService,
        public auth: AuthServiceService,
        public config: AppConfigService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        try {
            const [budgetResponse, userGroups] = await Promise.all([
                firstValueFrom(await this.budgetService.getAllYears()),
                this.auth.getGroups(),
            ]);

            Object.assign(this, { userGroups });

            const budgetYear = this.config.config.budgetData.currentYear;

            this.nextBudgetYear =
                BudgetUtilities.calcNextBudgetYear(budgetYear);

            this.budgetYears = budgetResponse
                .map((budget) => budget.budgetYear)
                .filter((budget) => budget !== this.nextBudgetYear);

            this.currentBudgetYear =
                this.budgetYears.find((budget) => budget === budgetYear) || '';

            this.selectedYears = this.currentBudgetYear;

            await this.updateSelectedBudgets();
            await this.updatePaymentList();
        } catch (error: any) {
            console.error(error);
        } finally {
            this.loading = false;
        }
    }

    async updateSelectedBudgets() {
        this.updating = true;

        try {
            this.selectedBudgets = (
                await firstValueFrom(
                    await this.budgetService.getSelectedBudgets([
                        this.selectedYears,
                    ])
                )
            ).sort(BudgetUtilities.sortYears);

            this.allUnits = await firstValueFrom(
                this.propertyDetails.getAllPayments(this.selectedYears)
            );

            this.allBoiler = await firstValueFrom(
                this.propertyDetails.getAllTrans(this.selectedYears, true)
            );

            await Promise.all([
                this.calculateTotalReceived(),
                this.calculateSCReceived(),
                this.calculateOtherReceived(),
                this.calcTotalBoiler(),
                this.calculateTotal(this.selectedBudgets[0], ''),
                this.updatePaymentList(),
            ]);

            this.totalExp = (+this.totalBoiler + +this.totalService).toFixed(2);
            this.totalOut = (+this.totalExp - +this.totalRec).toFixed(2);
        } catch (error: any) {
            console.error(error);
        } finally {
            this.updating = false;
        }
    }

    async calculateTotal(budget: Budget, code: string): Promise<void> {
        this.totalService = budget.budgetValues
            .filter((head) => head.code.includes(code))
            .map((head) => head.value)
            .reduce((a, b) => a + b || 0, 0)
            .toString();
    }

    calcTotalBoiler = async () => {
        this.totalBoiler = (
            this.allBoiler.reduce((a, b) => a + (b?.amount ?? 0), 0) * 2
        ).toFixed(2);
    };

    async calculateTotalReceived() {
        this.totalRec = this.allUnits
            .reduce(BudgetUtilities.reduceTransactionTotals, 0)
            .toFixed(2);
    }

    async calculateSCReceived() {
        this.serviceChargeReceived = this.allUnits
            .filter((trans) => !trans['unitFull#id'].includes('estate'))
            .reduce(BudgetUtilities.reduceTransactionTotals, 0)
            .toFixed(2);
    }

    async calculateOtherReceived() {
        this.otherRec = this.allUnits
            .filter((trans) => trans['unitFull#id'].includes('estate'))
            .reduce(BudgetUtilities.reduceTransactionTotals, 0)
            .toFixed(2);
    }

    async addTransaction() {
        try {
            this.loading = true;
            await firstValueFrom(
                this.propDetailsService.addTransaction(
                    {
                        ...this.paymentForm.getRawValue(),
                        transactionType: 'Payment',
                    },
                    'estate'
                )
            );

            this.paymentForm.reset({
                transactionType: 'Payment',
                date: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
            });

            await Promise.all([this.updateSelectedBudgets()]);
            this.showMessageBanner('Transaction added', 'SUCCESS');
        } catch (error: any) {
            console.error(error);
            this.showMessageBanner('Failed to add Transaction', 'ERROR');
        } finally {
            this.loading = false;
        }
    }

    async deleteTransaction(id: string, message: string) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
                message: `Are you sure you want to delete this Transaction? \n ${message}`,
            },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                try {
                    this.loading = true;
                    await firstValueFrom(
                        this.propDetailsService.removeTransaction(id, 'estate')
                    );

                    await Promise.all([this.updateSelectedBudgets()]);
                } catch (error: any) {
                    console.error(error);
                } finally {
                    this.loading = false;
                }
            }
        });
    }

    /**
     * Show Message banner
     *
     * @param text
     * @param type
     */
    showMessageBanner = (text: string, type: 'SUCCESS' | 'ERROR'): void => {
        this.messageText = text;
        this.messageBanner = type;
        setTimeout(() => {
            this.messageBanner = undefined;
            this.messageText = '';
        }, 5000);
    };

    /**
     * Get the Payment List for unit
     * @param unitFull
     */
    async updatePaymentList() {
        const { startDate, endDate } = this.config.config.budgetData;

        this.paymentArray = (
            await firstValueFrom(
                this.propDetailsService.getPaymentList('estate')
            )
        )
            .filter(
                BudgetUtilities.filterYear(
                    this.selectedYears,
                    startDate,
                    endDate
                )
            )
            .sort(
                (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
            );

        this.paymentButtons.fill(false, 0, this.paymentArray.length);
    }
}
