import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { NotificationService } from '../../services/notification/notification.service';
import { Subject, takeUntil } from 'rxjs';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { AppConfigService } from '../../services/app-config/app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyDetailsService } from '../../services/property-details/property-details.service';
import { UpdateServiceService } from 'src/app/services/update/update-service.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from '../modals/info-modal/info-modal.component';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ height: 0, opacity: 0 }),
                animate('300ms ease-out', style({ height: '*', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ height: '*', opacity: 1 }),
                animate('300ms ease-in', style({ height: 0, opacity: 0 })),
            ]),
        ]),
    ],
})
export class NavigationComponent implements OnInit {
    @ViewChild('sidenav') sideNav!: MatSidenav;
    mobile: boolean = false;
    mobileMenu: boolean = false;
    loading: boolean = true;
    approvals: number = 0;
    destroy$ = new Subject();
    userName!: string;
    userGroups!: (string | number)[];
    sites: string[] = [];
    backButtonRoute: string | undefined;

    constructor(
        public observer: BreakpointObserver,
        public notification: NotificationService,
        public auth: AuthServiceService,
        public configService: AppConfigService,
        public router: Router,
        public propertyService: PropertyDetailsService,
        public activeRoute: ActivatedRoute,
        public updateService: UpdateServiceService,
        public dialog: MatDialog
    ) {
        this.mobile = this.observer.isMatched('(max-width: 768px)');
    }

    async ngOnInit() {
        this.loading = true;
        try {
            this.userName = await this.auth.getUserName();
            this.userGroups = await this.auth.getGroups();
            const authLimit = await this.auth.getAuthLimit();
            this.notification.startPolling(this.userGroups);
            this.loading = true;
            this.notification.approvals
                .pipe(takeUntil(this.destroy$))
                .subscribe((approvals) => {
                    this.approvals =
                        (approvals?.workOrder?.filter(
                            (order) =>
                                Object.values(order.leaseGroups as {}).reduce(
                                    (a: number, b: any) => a + +b,
                                    0
                                ) <= authLimit
                        ).length || 0) + (approvals?.contractor?.length || 0);
                    this.loading = false;
                });

            this.updateService.isNewVersionAvailable
                .pipe(takeUntil(this.destroy$))
                .subscribe((updateAvailable) => {
                    if (updateAvailable === true) {
                        const dialogInst = this.dialog.open(
                            InfoModalComponent,
                            {
                                width: '500px',
                                data: {
                                    message:
                                        'There is a new update available! Please Refresh.',
                                    buttonText: 'Refresh',
                                },
                            }
                        );

                        dialogInst.afterClosed().subscribe(() => {
                            this.updateService.applyUpdate();
                        });
                    }
                });

            await this.configService.getConfigs();
            this.sites = this.configService.sites;
            await this.changeSite();
        } catch (error: any) {
            console.error(error.message);
        } finally {
            this.loading = false;
        }
    }

    /**
     * Angular lifecycle event
     */
    async ngAfterViewInit(): Promise<void> {
        this.observer.observe(['(max-width: 768px)']).subscribe((res) => {
            this.mobile = res.matches;
        });
    }

    /**
     * Log user out of App and return to landing page
     */
    logOut = async (): Promise<void> => {
        await this.auth.signOut();
    };

    toggleMenu = () => {
        this.mobileMenu = !this.mobileMenu;
    };

    changeSite = async () => {
        if (
            this.configService?.selectedSite ===
            this.configService?.config?.siteName
        )
            return;

        if (
            (await this.router.navigateByUrl('home-page/dashboard-page')) ||
            this.router.url === '/home-page/dashboard-page'
        ) {
            this.loading = true;
            await this.configService.getConfig(this.configService.selectedSite);
            this.propertyService.filterDetails = undefined;
            this.propertyService.reportData = undefined;
            this.loading = false;
        }

        if (this.configService?.config)
            this.configService.selectedSite =
                this.configService.config.siteName;
    };
}
