import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { startCase } from 'lodash';

@Component({
    selector: 'app-cw-select',
    templateUrl: './cw-select.component.html',
    styleUrls: ['./cw-select.component.css'],
})
export class CwSelectComponent implements AfterViewInit {
    @ViewChild('selectElement') selectElement!: ElementRef;
    @Input() options: any[] = [];
    @Input() controlName: string = '';
    @Input() label: string = '';
    @Input() formGroup!: FormGroup;
    @Input() emptyOption: boolean = false;
    @Input() showError: boolean = false;
    @Input() multiple: boolean = false;

    ngAfterViewInit() {
        if (this.multiple) this.selectElement.nativeElement.multiple = true;
    }

    isRequired() {
        const control = this.formGroup.get(this.controlName);
        if (!control || !control.validator) return false;

        const validator = control.validator({} as AbstractControl);

        return validator && validator['required'];
    }

    protected readonly startCase = startCase;
}
