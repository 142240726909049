<app-page-toolbar [pageTitle]="'Expenses'"></app-page-toolbar>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>

<div *ngIf="!loading">
  <mat-card>
    <mat-card-header>
      <mat-label>Select Year:</mat-label>
      <mat-select
        multiple
        [(ngModel)]="selectedYears"
        (ngModelChange)="updateSelectedExpenses()"
      >
        <mat-option *ngFor="let year of budgetYears" [value]="year">{{
            year
          }}
        </mat-option>
      </mat-select>
    </mat-card-header>
    <div *ngIf="updating" class="loadingSpinner">
      <mat-spinner></mat-spinner>
    </div>
    <div class="listDiv" *ngIf="!updating">
      <mat-list dense>
        <mat-list-item>
          <div class="inputDiv" *ngIf="expenses">
            <mat-label class="mat-body-strong catName"
            >Categories
            </mat-label
            >
            <div
              class="moneyDiv"
              *ngFor="let year of expenses | keyvalue"
            >
              <mat-label class="mat-body-strong">{{
                  year.key
                }}
              </mat-label>
              <mat-divider></mat-divider>
            </div>
          </div>
        </mat-list-item>
        <ng-container *ngFor="let head of budgetHeads">
          <mat-list-item>
            <div class="inputDiv" *ngIf="expenses">
              <mat-label class="catName font-poppins" [ngClass]="head.subCats ? 'font-bold':''">{{ head.name }}
              </mat-label>
              <div
                class="moneyDiv"
                *ngFor="let year of expenses | keyvalue"
              >
                <input [ngClass]="head.subCats ? 'font-bold':''"
                       currencyMask
                       [options]="{ prefix: '£ ' }"
                       matInput
                       autocomplete="off"
                       placeholder="0.00"
                       [value]="getModelValue(head, year.value)"
                       disabled
                />
              </div>
            </div>
          </mat-list-item>
          <mat-list-item *ngFor="let subCat of head?.subCats">
            <div class="inputDiv">
              <mat-label class="catName font-poppins">- {{ subCat.name }}
              </mat-label>
              <div
                class="moneyDiv"
                *ngFor="let year of expenses | keyvalue"
              >
                <input
                  currencyMask
                  [options]="{ prefix: '£ ' }"
                  matInput
                  autocomplete="off"
                  placeholder="0.00"
                  [value]="getModelValue(subCat, year.value)"
                  disabled
                />
              </div>
            </div>
          </mat-list-item>
          <mat-divider></mat-divider>

        </ng-container>

        <mat-list-item *ngIf="expenses">
          <div class="inputDiv">
            <mat-label class="mat-body-strong catName"
            >Total
            </mat-label
            >
            <div
              class="moneyDiv"
              *ngFor="let year of expenses | keyvalue"
            >
              <input
                currencyMask
                [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                class="mat-body-strong"
                matInput
                disabled
                [value]="+calculateTotal(year.value).toString()"
              />
            </div>
          </div>
        </mat-list-item>
        <mat-list-item></mat-list-item>
        <mat-list-item>
          <div class="inputDiv" *ngIf="expenses">
            <mat-label class="catName">RESERVES</mat-label>
            <div
              class="moneyDiv"
              *ngFor="let year of expenses | keyvalue"
            >
              <input
                currencyMask
                [options]="{ prefix: '£ ' }"
                matInput
                autocomplete="off"
                placeholder="0.00"
                [value]="
                                    getModelValue({ code: 'RES' }, year.value)
                                "
                disabled
              />
            </div>
          </div>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item></mat-list-item>
        <mat-list-item *ngIf="expenses">
          <div class="inputDiv">
            <mat-label class="mat-body-strong catName"
            >Grand Total
            </mat-label
            >
            <div
              class="moneyDiv"
              *ngFor="let year of expenses | keyvalue"
            >
              <input
                currencyMask
                [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                class="mat-body-strong"
                matInput
                disabled
                [value]="(
                                        +calculateTotal(year.value) +
                                        +getModelValue(
                                            { code: 'RES' },
                                            year.value
                                        )
                                    ).toString()"
              />
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card>
</div>
