import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { HomeComponent } from './pages/home/home.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { PropertyDetailsPageComponent } from './pages/property-details-page/property-details-page.component';
import { PropertyResultComponent } from './pages/property-details-page/property-result/property-result.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ReportViewerComponent } from './pages/reports/report-viewer/report-viewer.component';
import { CanDeactivateGuard } from './guards/deactivate/can-deactivate.guard';
import { ContractorSignUpComponent } from './pages/contractor-sign-up/contractor-sign-up.component';
import { ContractorsComponent } from './pages/contractors/contractors.component';
import { ViewContractorComponent } from './pages/contractors/view-contractor/view-contractor.component';
import { WorkOrdersComponent } from './pages/work-orders/work-orders.component';
import { CreateWorksOrderComponent } from './pages/work-orders/create-works-order/create-works-order.component';
import { ViewWorkOrderComponent } from './pages/work-orders/view-work-order/view-work-order.component';
import { ContractorDashboardComponent } from './pages/contractor-dashboard/contractor-dashboard.component';
import { BudgetsComponent } from './pages/finance/budgets/budgets.component';
import { ExpensesComponent } from './pages/finance/expenses/expenses.component';
import { IncomeComponent } from './pages/finance/income/income.component';
import { EditBudgetComponent } from './pages/finance/budgets/edit-budget/edit-budget.component';
import { VarianceComponent } from './pages/finance/variance/variance.component';
import { FinanceReportsComponent } from './pages/finance/reports/reports.component';
import { InvoiceRunComponent } from './pages/finance/reports/invoice-run/invoice-run.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { LeaseholderComponent } from './pages/leaseholder/leaseholder.component';
import { PaymentsComponent } from './pages/finance/reports/payments/payments.component';
import { DebtorsComponent } from './pages/finance/reports/debtors/debtors.component';
import { AdminPanelComponent } from './pages/admin-panel/admin-panel.component';

const routes: Routes = [
    { path: 'contractor-sign-up', component: ContractorSignUpComponent },
    {
        path: 'contractor/:id',
        component: ContractorDashboardComponent,
        canActivate: [],
        canDeactivate: [CanDeactivateGuard],
    },
    { path: 'account', component: LeaseholderComponent },

    {
        path: 'home-page',
        component: HomeComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: 'dashboard-page',
                component: DashboardPageComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'property-search',
                component: PropertyDetailsPageComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'property-results',
                component: PropertyResultComponent,
                canDeactivate: [CanDeactivateGuard],
                canActivate: [AuthGuardService],
            },
            {
                path: 'reports',
                component: ReportsComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'report-viewer',
                component: ReportViewerComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'contractors',
                component: ContractorsComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'view-contractor/:id',
                component: ViewContractorComponent,
                canDeactivate: [CanDeactivateGuard],
                canActivate: [AuthGuardService],
            },
            {
                path: 'work-orders',
                component: WorkOrdersComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'create-works-order',
                component: CreateWorksOrderComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'create-works-order/:orderNumber',
                component: CreateWorksOrderComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'view-work-order/:orderNumber',
                component: ViewWorkOrderComponent,
                canDeactivate: [CanDeactivateGuard],
                canActivate: [AuthGuardService],
            },
            {
                path: 'budgets',
                component: BudgetsComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'edit-budget',
                component: EditBudgetComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'expenses',
                component: ExpensesComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'variance',
                component: VarianceComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'income',
                component: IncomeComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'finance-reports',
                component: FinanceReportsComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'invoice-payments',
                component: InvoiceRunComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'payments-list',
                component: PaymentsComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'debtors',
                component: DebtorsComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'admin-panel',
                component: AdminPanelComponent,
                canActivate: [AuthGuardService],
            },

            { path: '', redirectTo: 'dashboard-page', pathMatch: 'full' },
        ],
    },
    { path: '', component: LandingPageComponent, pathMatch: 'full' },
    { path: '**', component: LandingPageComponent, pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
