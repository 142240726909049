import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BudgetsService } from '../../../services/budgets/budgets.service';
import { WorkOrdersService } from '../../../services/work-orders/work-orders.service';
import { WorkOrder } from '../../work-orders/work-orders.component';
import { isAfter, isBefore, parseISO } from 'date-fns';
import { AppConfigService } from '../../../services/app-config/app-config.service';
import { BudgetHeadConst } from '../../../constants/budgets';

@Component({
    selector: 'app-expenses',
    templateUrl: './expenses.component.html',
    styleUrls: ['./expenses.component.css'],
})
export class ExpensesComponent implements OnInit {
    loading: boolean = true;
    updating = true;
    totalValue = 0;
    budgetYears: string[] = [];
    currentBudgetYear!: string;
    nextBudgetYear!: string;
    budgetHeads: BudgetHeadConst[] = [];
    allWorkOrders!: WorkOrder[];
    filteredWorkOrders: {
        [key: string]: WorkOrder[];
    } = {};
    selectedYears: string[] = [];
    relevantWorkOrder!: WorkOrder[];
    expenses: { [key: string]: { [key: string]: number } } = {};

    constructor(
        private budgetService: BudgetsService,
        private workOrderService: WorkOrdersService,
        public appConfig: AppConfigService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        const budgetResponse = await firstValueFrom(
            await this.budgetService.getAllYears()
        );

        this.budgetHeads = [...this.appConfig.config.budgetData.heads].sort(
            (a, b) => a.name.localeCompare(b.name)
        );

        const budgetYear = this.budgetService.calculateBudgetYear();
        this.nextBudgetYear =
            (+budgetYear + 1).toString() + '-' + (+budgetYear + 2).toString();
        this.budgetYears = budgetResponse
            .map((budget) => budget.budgetYear)
            .filter((budget) => budget !== this.nextBudgetYear);
        this.currentBudgetYear =
            this.budgetYears.find(
                (budget) => budget.split('-')[0] === budgetYear
            ) || '';
        this.selectedYears.push(this.currentBudgetYear);
        this.loading = false;
        await this.updateSelectedExpenses();
    }

    async updateSelectedExpenses() {
        if (this.selectedYears.length > 0 && this.selectedYears.length <= 3) {
            this.updating = true;
            if (!this.allWorkOrders)
                this.allWorkOrders = await firstValueFrom(
                    await this.workOrderService.getExpenses()
                );
            this.relevantWorkOrder = this.allWorkOrders;

            this.filteredWorkOrders = {};
            this.selectedYears.forEach((year: string) => {
                const budgetYear = year.split('-')[0];
                this.filteredWorkOrders[year] = this.relevantWorkOrder.filter(
                    (workOrder) => {
                        const approvalDate = workOrder.audits?.find(
                            (audit) => audit.event === 'Invoice Approved'
                        )!.date;
                        if (!approvalDate) return false;

                        return (
                            isAfter(
                                parseISO(approvalDate),
                                new Date(+budgetYear, 8, 1)
                            ) &&
                            isBefore(
                                parseISO(approvalDate),
                                new Date(+budgetYear + 1, 8, 1)
                            )
                        );
                    }
                );
            });

            this.expenses = {};

            for (const [key, value] of Object.entries(
                this.filteredWorkOrders
            )) {
                this.expenses[key] = {};

                this.expenses[key]['RES'] = value
                    .filter((order) => order.expenseCategory === 'RES')
                    .map(
                        (order) =>
                            Object.values(order.leaseGroups).reduce(
                                (a, b) => a + b
                            ),
                        0
                    )
                    .reduce((a, b) => a + b, 0);

                this.budgetHeads.forEach((head) => {
                    let subTotal = 0;

                    if (head.subCats) {
                        head.subCats.forEach((subCat) => {
                            this.expenses[key][subCat.code] = value
                                .filter(
                                    (order) =>
                                        order.expenseCategory === subCat.code
                                )
                                .map((order) =>
                                    Object.values(order.leaseGroups).reduce(
                                        (a, b) => a + b
                                    )
                                )
                                .reduce((a, b) => a + b, 0);

                            subTotal += this.expenses[key][subCat.code];
                        });
                    }

                    this.expenses[key][head.code] = value
                        .filter((order) => order.expenseCategory === head.code)
                        .map((order) =>
                            Object.values(order.leaseGroups).reduce(
                                (a, b) => a + b
                            )
                        )
                        .reduce((a, b) => a + b, subTotal);
                });
            }

            this.updating = false;
            console.log(this.expenses);
        }
    }

    getModelValue(head: { code: any }, expenses: { [key: string]: number }) {
        return expenses[head.code] || 0;
    }

    calculateTotal(expenses: { [key: string]: number }): string {
        return this.budgetHeads
            .map((head) => {
                return expenses[head.code];
            })
            .reduce((a, b) => a + b)
            .toString();
    }
}
