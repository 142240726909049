import { Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import {
    AuthServiceService,
    UserGroups,
} from '../auth-service/auth-service.service';

@Injectable({
    providedIn: 'root',
})
export class UpdateServiceService {
    isNewVersionAvailable: BehaviorSubject<boolean> = new BehaviorSubject(
        false
    );
    intervalSource = interval(30 * 60 * 1000); // every 30 mins
    intervalSubscription?: Subscription;

    constructor(
        public swUpdate: SwUpdate,
        private zone: NgZone,
        private authService: AuthServiceService
    ) {
        this.zone.runOutsideAngular(() => {
            this.swUpdate.versionUpdates.subscribe(async (evt) => {
                switch (evt.type) {
                    case 'VERSION_DETECTED':
                        console.log(
                            `Downloading new app version: ${evt.version.hash}`
                        );
                        break;
                    case 'VERSION_READY':
                        this.isNewVersionAvailable.next(true);
                        await this.autoUpdate();
                        break;
                    case 'VERSION_INSTALLATION_FAILED':
                        console.log(
                            `Failed to install app version '${evt.version.hash}': ${evt.error}`
                        );
                        break;
                }
            });

            this.swUpdate.unrecoverable.subscribe(() =>
                document.location.reload()
            );

            if (this.swUpdate.isEnabled) {
                this.intervalSubscription = this.intervalSource.subscribe(
                    async () => {
                        await this.check();
                    }
                );
            }
        });

        this.check();
    }

    /**
     * Check for new app updates
     */
    async check(): Promise<void> {
        try {
            if (this.swUpdate.isEnabled) await this.swUpdate.checkForUpdate();
        } catch (error) {
            console.error('Failed to check for updates:', error);
        }
    }

    /**
     * Auto update app if not logged in or not staff
     */
    async autoUpdate() {
        const loggedIn: boolean = this.authService.isLoggedIn();
        const groups: (string | number)[] = await this.authService.getGroups();

        if (!loggedIn || (loggedIn && !groups.includes(UserGroups.Staff))) {
            this.applyUpdate();
        }
    }

    /**
     * Apply the latest updates and reload the page
     */
    applyUpdate(): void {
        this.swUpdate
            .activateUpdate()
            .then(() => document.location.reload())
            .catch((error) => console.error('Failed to apply updates:', error));
    }
}
