<app-page-toolbar [backButtonRoute]="'/home-page/budgets'"></app-page-toolbar>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>

<div class="mx-auto flex w-full max-w-7xl items-start mobile:flex-col tablet:flex-row desktop:flex-row">
  <app-cw-card class="flex-1 p-0 m-0 mobile:w-full mt-5" [showFooter]="false">
    <card-icon class="w-full">
      <div class="w-full">
        <div class="block w-full">
          <nav class="flex space-x-1 w-full" aria-label="Tabs">
            <a (click)="tabSelected='heads'"
               [ngClass]="tabSelected === 'heads' ? 'bg-cw-blue text-white':'hover:text-gray-700'"
               class="rounded-md px-3 py-2 text-sm font-medium cursor-pointer w-[33%] text-center">Budget</a>
            <a (click)="tabSelected='reserves'"
               [ngClass]="tabSelected === 'reserves' ? 'bg-cw-blue text-white':'hover:text-gray-700'"
               class="rounded-md px-3 py-2 text-sm font-medium cursor-pointer w-[33%] text-center">Reserves</a>

          </nav>
        </div>
      </div>
    </card-icon>

    <card-body *ngIf="newBudgetForm" [formGroup]="newBudgetForm">
      <div [hidden]="tabSelected !== 'heads'">
        <div class="">
          <div class=" flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block  py-2 align-middle sm:px-6 lg:px-8 w-full">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[400px]">
                        Categories
                      </th>
                      <th scope="col" class="pr-6 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]"
                      >
                        {{ nextBudgetYear }}
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                    <ng-container *ngFor="let head of budgetHeads; index as i">

                      <ng-container *ngIf="!head?.subCats" class="hover:bg-gray-100">
                        <tr
                          class="hover:bg-gray-100">
                          <td class="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6">

                            {{ head.name }}

                          </td>
                          <td
                            class=" px-3 py-1 text-xs text-gray-500 text-right w-[150px]"><input
                            class="text-gray-900 w-[150px] border-none bg-transparent"

                            currencyMask
                            [options]="{ prefix: '£ ' }"
                            matInput
                            autocomplete="off"
                            placeholder="0.00"
                            formControlName="{{ head.code }}"
                          />
                          </td>
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="head?.subCats">
                        <tr
                          class="hover:bg-gray-100">
                          <td colspan="4">
                            <table class="min-w-full">
                              <thead>
                              <tr>
                                <th scope="col"
                                    class=" pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6 w-[400px]">

                                  {{ head.name }}

                                </th>
                                <th scope="col"
                                    class="px-3 text-sm text-gray-900 text-right w-[150px]"
                                >
                                  <input class="text-gray-900 w-[150px] border-none bg-transparent"

                                         currencyMask
                                         [options]="{ prefix: '£ ' }"
                                         matInput
                                         autocomplete="off"
                                         placeholder="0.00"
                                         [ngModelOptions]="{
                                                standalone: true
                                            }"
                                         [ngModel]="
                                                calculateCatValue(head?.subCats)
                                            "
                                         disabled
                                  />
                                </th>

                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let subCat of head.subCats"
                                  class="hover:bg-gray-100">
                                <td
                                  class="whitespace-nowrap pl-6 pr-3 text-xs text-gray-900 sm:pl-6 w-[400px]">

                                  - {{ subCat.name }}

                                </td>
                                <td
                                  class=" px-3 text-xs text-gray-500 text-right"><input
                                  class="text-gray-900 w-[150px] border-none bg-transparent"
                                  currencyMask
                                  [options]="{ prefix: '£ ' }"
                                  matInput
                                  autocomplete="off"
                                  placeholder="0.00"
                                  formControlName="{{ subCat.code }}"
                                />
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>


                        </tr>


                      </ng-container>

                    </ng-container>


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div [hidden]="tabSelected !== 'reserves'">
        <div class="">
          <div class=" flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block  py-2 align-middle sm:px-6 lg:px-8 w-full">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-[400px]">
                        Categories
                      </th>
                      <th scope="col" class="pr-6 py-3.5 text-right text-sm font-semibold text-gray-900 w-[150px]"
                      >
                        {{ nextBudgetYear }}
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                    <ng-container *ngFor="let head of reserveHeads">

                      <ng-container class="hover:bg-gray-100">
                        <tr
                          class="hover:bg-gray-100">
                          <td class="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6">

                            {{ head.name }}

                          </td>
                          <td
                            class=" px-3 py-1 text-xs text-gray-500 text-right w-[150px]"><input
                            class="text-gray-900 w-[150px] border-none bg-transparent"
                            currencyMask
                            [options]="{ prefix: '£ ' }"
                            matInput
                            autocomplete="off"
                            placeholder="0.00"
                            formControlName="{{ head.code }}"
                          />
                          </td>
                        </tr>
                      </ng-container>


                    </ng-container>


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card-body>
  </app-cw-card>

  <aside
    class="sticky flex-col shrink-0 mr-5 ml-0 w-full tablet:w-96 order-first tablet:order-last top-[20px] tablet:top-[155px]">
    <div class="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 mx-5 tablet:mx-0 mt-5 tablet:mt-0">
      <dl class="flex flex-wrap">
        <div class="flex-auto px-6 pt-6">
          <h3
            class="mb-0 font-poppins">{{ nextBudgetYear }}</h3>


        </div>
        <div class="mt-6 w-full flex-col flex-none gap-y-2 border-t border-gray-900/5 px-6 pt-6 flex"
        >
          <div class="bg-gray-50 grid grid-cols-2 ">
            <dt class="font-poppins text-sm font-medium leading-6 text-gray-900">Budget Total</dt>
            <dd
              class="font-poppins  text-sm leading-6 text-gray-700 col-span-1 mt-0"><input
              class="text-gray-900 w-[150px] border-none bg-transparent py-0"
              currencyMask
              [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"

              disabled
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="budgetValue"
            />
            </dd>
          </div>
          <div class="bg-gray-50 grid grid-cols-2 ">
            <dt class="font-poppins text-sm font-medium leading-6 text-gray-900">Reserves Total</dt>
            <dd
              class="font-poppins  text-sm leading-6 text-gray-700 col-span-1 mt-0"><input
              class="text-gray-900 w-[150px] border-none bg-transparent py-0"
              currencyMask
              [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"

              disabled
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="reserveValue"
            />
            </dd>
          </div>
          <hr>
          <div class="bg-gray-50 grid grid-cols-2 ">
            <dt class="font-poppins text-sm font-bold leading-6 text-gray-900">Grand Total</dt>
            <dd
              class="font-poppins  text-sm leading-6 text-gray-700 col-span-1 mt-0"><input
              class="text-gray-900 w-[150px] border-none bg-transparent font-bold py-0"
              currencyMask
              [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"

              disabled
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="totalValue"
            />
            </dd>
          </div>
        </div>

      </dl>
      <div *ngIf="newBudgetForm"
           class="mt-6 flex flex-col justify-center justify-items-stretch border-t border-gray-900/5 gap-x-2 px-6 py-6 w-full">

        <p *ngIf="newBudgetForm && newBudgetForm.dirty" class="w-full text-center">Unsaved Changes</p>
        <div class="flex flex-row justify-items-stretch gap-x-2">
          <app-cw-secondary-button buttonText="Cancel"
                                   [isDisabled]=" !newBudgetForm.dirty" (onClick)="createForm()"
                                   class="w-1/2"></app-cw-secondary-button>
          <app-cw-primary-button buttonText="{{ saving ? 'Saving...' : 'Save'}}"
                                 class="w-1/2"
                                 [isDisabled]=" !newBudgetForm.dirty || newBudgetForm.dirty && newBudgetForm.invalid || loading"
                                 (onClick)="updateBudget()"></app-cw-primary-button>
        </div>
        <app-banner *ngIf="messageBanner" [eventType]="messageBanner" [message]="messageText"></app-banner>


      </div>

    </div>


  </aside>
</div>


<
