<div [formGroup]="formGroup"
     class="relative my-4">
  <label for="{{ controlName }}"
         class="absolute -top-2 left-2 inline-block bg-slate-50 px-1 text-xs font-medium text-gray-900">{{ isRequired() ? label + '*' : label }}</label>
  <input type="date" id="{{ controlName }}" name="{{ controlName }}" formControlName="{{ controlName }}"
         [ngClass]="showError && formGroup.controls[controlName].touched && formGroup.controls[controlName].errors ? 'ring-red-300' : 'ring-gray-300' "

         class="block font-poppins h-12 bg-slate-50 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cw-blue sm:text-sm sm:leading-6">
</div>

<p *ngIf="showError && formGroup.controls[controlName].touched && formGroup.controls[controlName].errors?.['required']"
   class="text-xs text-red-600 text-center"
   id="email-error">
  This field is required</p>
