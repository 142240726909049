import { Component, OnInit } from '@angular/core';
import { PropertyDetailsService } from '../../services/property-details/property-details.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { PropertyDetail } from '../../interfaces/property';
import { InfoModalComponent } from '../../components/modals/info-modal/info-modal.component';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-property.ts-details-page',
    templateUrl: './property-details-page.component.html',
    styleUrls: ['./property-details-page.component.css'],
})
export class PropertyDetailsPageComponent implements OnInit {
    public loading: boolean = false;
    searchName!: string;
    searchField: 'leaseholder' | 'tenant' = 'leaseholder';
    showSearchField: 'leaseholder' | 'tenant' = 'leaseholder';
    searchResults!: Partial<PropertyDetail[]>;
    searching: boolean = false;
    disableList: boolean = false;
    unitSearchSelect: { value: string; unitFull: string }[] = [];
    unitSearchTerm: string = '';
    unitSearchResults: { value: string; unitFull: string }[] = [];

    propSearch = new FormGroup({
        field: new FormControl<'leaseholder' | 'tenant'>(
            'leaseholder',
            Validators.required
        ),
        searchValue: new FormControl<string>('', [
            Validators.required,
            Validators.minLength(3),
        ]),
    });

    constructor(
        public propDetailsService: PropertyDetailsService,
        public router: Router,
        public dialog: MatDialog
    ) {}

    /** Angular lifecycle event */
    async ngOnInit(): Promise<void> {
        this.loading = true;
        this.unitSearchSelect =
            (await this.propDetailsService.getUnitSearchDetails()) || [];
        this.loading = false;
    }

    /** Get Property details using filter */
    async submitViewProperty(criteria?: string): Promise<boolean | undefined> {
        let searchCriteria;
        if (criteria) {
            searchCriteria = criteria;
            this.disableList = true;
        } else {
        }

        if (searchCriteria) {
            try {
                const encodedParam = new HttpUrlEncodingCodec().encodeValue(
                    searchCriteria
                );

                return this.router.navigateByUrl(
                    `home-page/property-results?from=search&unit=${encodedParam}`
                );
            } catch (error: any) {
                this.disableList = false;
                this.openInfoDialog('Error connecting to Database');
            }
        }
        this.disableList = false;
        return;
    }

    /** Open Info Dialog Box */
    openInfoDialog = (message: string) => {
        this.dialog.open(InfoModalComponent, {
            width: '500px',
            data: { message },
        });
    };

    async getSearchResults() {
        const { field, searchValue } = this.propSearch.getRawValue();

        if (!searchValue || searchValue.length < 3) return;
        this.searching = true;
        this.searchResults = await firstValueFrom(
            this.propDetailsService.callSearchEndpoint(
                searchValue,
                field as 'leaseholder' | 'tenant'
            )
        );
        this.showSearchField = field as 'leaseholder' | 'tenant';
        this.searching = false;
    }

    getUnitSearchResults() {
        this.unitSearchResults = this.unitSearchSelect.filter((unit) =>
            unit.value
                .toLowerCase()
                .replace(/\s/g, '')
                .startsWith(
                    this.unitSearchTerm.toLowerCase().replace(/\s/g, '')
                )
        );
    }

    protected readonly document = document;
    protected readonly String = String;
    protected readonly event = event;
}
