<div *ngIf="
                !(
                    section.headerDetails.section === 'Boiler Details' &&
                    propertyDetails.unitType === 'Town House'
                ) && section.headerDetails.section !== 'Property Address'
            " class="mb-4">
  <div class="relative">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300"></div>
    </div>
    <div class="relative flex justify-center">
                <span
                  class="bg-slate-50 font-poppins px-3 text-base font-semibold leading-6 text-gray-900">{{ section.headerDetails.section }}</span>
    </div>
  </div>
  <div *ngFor="let field of section.data; let i = index">

    <div class="relative" *ngIf="calcSubSectionIndexes(section).includes(i)">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
      </div>
      <div class="relative flex justify-center">
                <span
                  class="bg-slate-50 font-poppins px-3 text-sm  leading-6 text-gray-900">{{ startCase(field.subSection) }}</span>
      </div>
    </div>


    <app-cw-text *ngIf="field.type === 'text'" [label]="startCase(field.fieldName)"
                 [formGroup]="form" [controlName]="field.fieldName" [showError]="true">

    </app-cw-text>

    <app-cw-date *ngIf="field.type === 'date'" [label]="startCase(field.fieldName)"
                 [formGroup]="form" [controlName]="field.fieldName">

    </app-cw-date>

    <app-cw-text-area *ngIf="field.type === 'textArea'" [label]="startCase(field.fieldName)"
                      [formGroup]="form" [controlName]="field.fieldName"
    ></app-cw-text-area>


    <app-cw-select *ngIf="field.type === 'select'" [label]="startCase(field.fieldName)"
                   [formGroup]="form" [controlName]="field.fieldName"
                   [options]="getOptions(field.selectOptions)"
                   [showError]="true">

    </app-cw-select>


  </div>
</div>
