import { endOfDay, isWithinInterval, startOfDay } from 'date-fns';
import { Budget } from '../interfaces/budget-interfaces';
import { Transaction } from '../interfaces/property';

export class BudgetUtilities {
    /**
     * Return Budget years in ascending order
     * @param a
     * @param b
     * @returns
     */
    static sortYears = (a: Budget, b: Budget) =>
        +a.budgetYear.split('-')[0] - +b.budgetYear.split('-')[0];

    /**
     *  Calculate the Next BudgetYear
     * @param budgetYear
     * @returns
     */
    static calcNextBudgetYear = (budgetYear: string) =>
        budgetYear
            .split('-')
            .map((year) => +year + 1)
            .join('-');

    /**
     * Filter transactions for the year
     * @param budgetYear
     * @returns
     */
    static filterYear = (
        budgetYear: string,
        startDate: string,
        endDate: string
    ) => {
        const [startYear, endYear] = budgetYear.split('-');

        const start: Date = startOfDay(
            new Date(startDate).setFullYear(+startYear)
        );
        const end: Date = endOfDay(new Date(endDate).setFullYear(+endYear));

        return (transaction: Transaction) =>
            isWithinInterval(new Date(transaction.date), {
                start,
                end,
            });
    };

    static reduceTransactionTotals = (
        total: number,
        transaction: Transaction
    ) =>
        transaction.transactionType === 'Payment'
            ? total + transaction.amount
            : total - transaction.amount;
}
