import { Component, OnInit, ViewChild } from '@angular/core';
import { PropertyDetail, Transaction } from '../../../../interfaces/property';
import { isSameDay, isWithinInterval } from 'date-fns';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {
    MatLegacyTable as MatTable,
    MatLegacyTableDataSource as MatTableDataSource,
} from '@angular/material/legacy-table';
import { PropertyDetailsService } from '../../../../services/property-details/property-details.service';
import { BudgetsService } from '../../../../services/budgets/budgets.service';
import { NGXLogger } from 'ngx-logger';
import { AuthServiceService } from '../../../../services/auth-service/auth-service.service';
import { firstValueFrom } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config/app-config.service';

@Component({
    selector: 'app-debtors',
    templateUrl: './debtors.component.html',
    styleUrls: ['./debtors.component.css'],
})
export class DebtorsComponent implements OnInit {
    loading: boolean = false;
    startDate!: Date;
    endDate!: Date;
    allUnits!: PropertyDetail[];
    displayColumns = [
        'unitFull',
        'unitName',
        'charge',
        'balance',
        'percentage',
    ];
    filteredData?: {
        unitFull: string;
        serviceCharge: number;
        balance: number;
        percent: number;
    }[] = [];
    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
    totalIncome!: number;
    fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    fileExtension = '.xlsx';
    minPercent!: number;
    maxPercent!: number;
    filterMax!: number;
    filterMin!: number;

    @ViewChild(MatTable) table!: MatTable<any>;

    constructor(
        public propertyDetails: PropertyDetailsService,
        public budgetService: BudgetsService,
        public logger: NGXLogger,
        public auth: AuthServiceService,
        public appConfig: AppConfigService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;

        try {
            this.allUnits = await this.propertyDetails.getReportData(true);
            const allTrans = await firstValueFrom(
                this.propertyDetails.getAllTrans()
            );

            const { currentYear, startDate, endDate } =
                this.appConfig.config.budgetData;

            this.filteredData = this.allUnits
                .map((unit: PropertyDetail) => {
                    const unitTrans = allTrans.filter(
                        (trans) => trans.unitFull === unit.unitFull
                    );

                    const serviceChargeAmount = unitTrans.find(
                        (payment: Transaction) =>
                            isWithinInterval(new Date(payment.date), {
                                start: new Date(startDate),
                                end: new Date(endDate),
                            }) &&
                            payment.transactionType === 'Charge' &&
                            payment.description.includes(
                                'Service Charge Demand'
                            )
                    )?.amount;

                    const reserveChargeAmount = unitTrans.find(
                        (payment: Transaction) =>
                            isWithinInterval(new Date(payment.date), {
                                start: new Date(startDate),
                                end: new Date(endDate),
                            }) &&
                            payment.transactionType === 'Charge' &&
                            payment.description.includes(
                                'Reserve Contributions'
                            )
                    )?.amount;

                    const balance = unitTrans?.reduce((total, trans) => {
                        return trans.transactionType === 'Charge'
                            ? total + trans.amount
                            : total - trans.amount;
                    }, 0);

                    const percent =
                        !serviceChargeAmount || !balance || !reserveChargeAmount
                            ? 0
                            : balance /
                              (serviceChargeAmount + reserveChargeAmount);

                    return {
                        unitFull: unit.unitFull,
                        ownersName: unit.ownerDetails.name,
                        ownersEmail: unit.ownerDetails.email1 || '',
                        serviceCharge:
                            (serviceChargeAmount ?? 0) +
                            (reserveChargeAmount ?? 0),
                        balance: balance ?? 0,
                        percent,
                    };
                })
                .filter((unit) => unit.percent * 100 > 1)
                .sort((a: any, b: any) => b.percent - a.percent);

            this.maxPercent = +(this.filteredData[0].percent * 100).toFixed(0);
            this.minPercent = +(
                this.filteredData[this.filteredData.length - 1].percent * 100
            ).toFixed(0);

            this.filterMax = this.maxPercent;
            this.filterMin = this.minPercent;

            this.dataSource = new MatTableDataSource<any>(this.filteredData);
        } catch {
        } finally {
            this.loading = false;
        }
    }

    filterData() {
        const filtered =
            this.filteredData?.filter((entry) => {
                return (
                    entry.percent >= this.filterMin / 100 &&
                    entry.percent * 100 <= this.filterMax + 1
                );
            }) || [];

        this.dataSource = new MatTableDataSource<any>(filtered);
        this.table.renderRows();
    }

    resetData() {
        this.filterMax = this.maxPercent;
        this.filterMin = this.minPercent;
        this.dataSource = new MatTableDataSource<any>(this.filteredData);
        this.table.renderRows();
    }

    exportReport() {
        const formattedData: Partial<PropertyDetail>[] =
            this.dataSource?.data.map((unit) => ({
                ...unit,
                percent: (unit.percent * 100).toFixed(2),
            })) ?? [];

        const workSheet: XLSX.WorkSheet =
            XLSX.utils.json_to_sheet(formattedData);
        const workBook: XLSX.WorkBook = {
            Sheets: { data: workSheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workBook, {
            bookType: 'xlsx',
            type: 'array',
        });
        const data: Blob = new Blob([excelBuffer], { type: this.fileType });
        saveAs(data, `CWE_debtors_list` + this.fileExtension);
    }
}
