<app-page-toolbar [backButtonRoute]="'/home-page/work-orders'">
</app-page-toolbar>

<mat-card class="titleCard">
  <mat-card-title>
    <mat-icon>receipt</mat-icon>
    <div class="buttonBar">
      <button
        mat-icon-button
        [matTooltip]="'Copy to New'"
        *ngIf="workOrder"
        [routerLink]="[
                    '/home-page/create-works-order',
                    workOrder.orderNumber
                ]"
      >
        <mat-icon> content_copy</mat-icon>
      </button>
      <button
        mat-icon-button
        [matTooltip]="'Print'"
        *ngIf="workOrder"
        (click)="getPrintPDF()"
        [disabled]="printing"
      >
        <mat-icon> print</mat-icon>
      </button>
    </div>

    <mat-label class="title">Order: #{{ orderNumber }}</mat-label>
  </mat-card-title>
</mat-card>
<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>

<mat-expansion-panel
  *ngIf="!loading"
  [expanded]="
        fileList &&
        fileList.length > 0 &&
        fileList.includes(orderNumber + '/invoice.pdf')
    "
>
  <mat-expansion-panel-header>
    <mat-icon>upload_file</mat-icon>
    <mat-label>Upload Invoice</mat-label>
  </mat-expansion-panel-header>
  <div
    *ngIf="
            !fileList ||
            fileList.length === 0 ||
            !fileList.includes(orderNumber + '/invoice.pdf')
        "
  >
    <small
    >Please upload an Invoice for work completed against this Work
      Order. Once uploaded, it will be sent for approval.</small
    >

    <mat-form-field
      appearance="outline"
      (click)="
                ![
                    'Awaiting Payment',
                    'Invoice Paid',
                    'Closed',
                    'Awaiting Authorisation'
                ].includes(workOrder.status)
                    ? fileInput.openFilePicker()
                    : null
            "
    >
      <ngx-mat-file-input
        #fileInput
        [(ngModel)]="invoice"
        accept=".pdf"
        [disabled]="
                    [
                        'Awaiting Payment',
                        'Invoice Paid',
                        'Closed',
                        'Awaiting Authorisation'
                    ].includes(workOrder.status)
                "
      >
      </ngx-mat-file-input>
    </mat-form-field>
    <small *ngIf="!!invoice && !isCorrectType(['application/pdf'], invoice)"
    >Unsupported file type. Please upload PDF</small
    >
    <mat-progress-bar
      *ngIf="uploading"
      mode="determinate"
      value="{{ progress }}"
    ></mat-progress-bar>
    <button
      [disabled]="
                !invoice ||
                (!!invoice && !isCorrectType(['application/pdf'], invoice))
            "
      mat-raised-button
      class="invoiceUpload"
      (click)="uploading ? cancelUpload() : uploadFile(invoice)"
    >
      {{ uploading ? 'Cancel' : 'Upload Invoice' }}
    </button>
  </div>
  <div
    *ngIf="
            fileList &&
            fileList.length > 0 &&
            fileList.includes(orderNumber + '/invoice.pdf')
        "
  >
    <div class="fileDiv">
      <button
        mat-raised-button
        id="viewInvoice"
        (click)="
                    openFile(orderNumber + '/invoice.pdf');
                    $event.preventDefault()
                "
      >
        View Invoice
      </button>
      <button
        mat-raised-button
        color="warn"
        id="deleteButton"
        (click)="deleteFile(orderNumber + '/invoice.pdf')"
        [disabled]="
                    ['Awaiting Payment', 'Invoice Paid', 'Closed'].includes(
                        workOrder.status
                    )
                "
      >
        Delete Invoice
      </button>
    </div>
  </div>
</mat-expansion-panel>

<mat-expansion-panel
  *ngIf="!loading"
  [expanded]="fileList && fileList.length > 0"
>
  <mat-expansion-panel-header>
    <mat-icon>upload_file</mat-icon>
    <mat-label>Upload Supporting Documents</mat-label>
  </mat-expansion-panel-header>
  <div>
    <small>Please upload any supporting Documents</small>
    <mat-form-field
      appearance="outline"
      (click)="
                ![
                    'Awaiting Payment',
                    'Invoice Paid',
                    'Closed',
                    'Awaiting Authorisation'
                ].includes(workOrder.status)
                    ? supportInput.openFilePicker()
                    : null
            "
      id="fileInput"
    >
      <ngx-mat-file-input
        #supportInput
        [(ngModel)]="supportDocs"
        accept=".pdf,image/*,.doc,.docx"
        placeholder="Select Files"
        [disabled]="
                    [
                        'Awaiting Payment',
                        'Invoice Paid',
                        'Closed',
                        'Awaiting Authorisation'
                    ].includes(workOrder.status)
                "
      >
      </ngx-mat-file-input>
    </mat-form-field>
    <small
      *ngIf="
                supportDocs &&
                !isCorrectType(
                    ['application/pdf', 'image/jpeg', 'image/png'],
                    supportDocs
                )
            "
    >Unsupported file type. Please upload .pdf / .jpeg / .png
    </small>
    <mat-progress-bar
      *ngIf="docsUploading"
      mode="indeterminate"
    ></mat-progress-bar>
    <button
      [disabled]="
                !supportDocs ||
                (supportDocs &&
                    !isCorrectType(
                        ['application/pdf', 'image/jpeg', 'image/png'],
                        supportDocs
                    ))
            "
      mat-raised-button
      class="invoiceUpload"
      (click)="
                docsUploading
                    ? cancelUploadDocs()
                    : uploadSupportingDocs(supportDocs)
            "
    >
      {{ docsUploading ? 'Cancel' : 'Upload File' }}
    </button>
  </div>
  <ul *ngIf="fileList && fileList.length > 0 && supportingFiles().length > 0">
    <li *ngFor="let file of supportingFiles()" class="supportDiv">
      <mat-label (click)="openFile(file); $event.preventDefault()">{{
          file.replace(orderNumber + '/supportingDocs/', '')
        }}
      </mat-label>
      <button
        mat-icon-button
        color="warn"
        (click)="deleteFile(file)"
        [disabled]="
                    ['Awaiting Payment', 'Invoice Paid'].includes(
                        workOrder.status
                    )
                "
      >
        <mat-icon>close</mat-icon>
      </button>
    </li>
  </ul>
</mat-expansion-panel>

<mat-card [formGroup]="workOrderForm" *ngIf="workOrderForm && !loading">
  <mat-card-content>
    <mat-form-field appearance="outline">
      <mat-label>Date Created</mat-label>
      <input
        matInput
        value="{{
                    workOrder.dateCreated | date: 'dd/MM/yyyy  -  h:mm a'
                }}"
        disabled
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Status</mat-label>
      <input matInput value="{{ workOrder.status }}" disabled />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{
          loading ? 'Loading Contractors...' : 'Select Contractor'
        }}
      </mat-label>
      <mat-select
        formControlName="contractorId"
        [compareWith]="compareContractors"
      >
        <mat-option
          *ngFor="let contractor of approvedContractors"
          [value]="contractor"
        >{{ contractor?.companyName }}
        </mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{
          loading
            ? 'Loading Expense Categories...'
            : 'Select Expense Category'
        }}
      </mat-label>
      <mat-select formControlName="expenseCategory">
        <ng-container *ngFor="let expense of expenseCategory">
          <mat-option
            [value]="expense.code"
          >
            <ng-container *ngIf="expense.subCats">
              <b>**DO NOT USE** <strike>{{ expense.name }}</strike> **DO NOT USE**</b>
            </ng-container>
            <ng-container *ngIf="!expense.subCats">
              {{ expense.name }}
            </ng-container>
          </mat-option
          >
          <ng-container *ngIf="expense.subCats">
            <mat-option *ngFor="let subCat of expense.subCats" [value]="subCat.code">
              {{ subCat.name }}
            </mat-option>
          </ng-container>
        </ng-container>

        <mat-option [value]="'RES'">RESERVES</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{
          loading ? 'Loading Lease Groups...' : 'Select Lease Groups'
        }}
      </mat-label>
      <mat-select
        [multiple]="true"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="selectedLeaseGroups"
        (ngModelChange)="onSelectedLeaseGroupChange()"
        [disabled]="workOrderForm.controls['leaseGroups'].disabled"
      >
        <mat-option
          *ngFor="let group of leaseGroups"
          [value]="group.code"
        >{{ group.name }}
        </mat-option
        >
      </mat-select>
    </mat-form-field>

    <div
      class="listDiv"
      *ngIf="selectedLeaseGroups.length > 0"
      [formGroup]="getLeaseGroupsForms()"
    >
      <mat-list dense>
        <mat-list-item
          *ngFor="
                        let group of getLeaseGroupsForms().controls | keyvalue
                    "
        >
          <div class="inputDiv">
            <mat-label>{{ scheduleDisplay(group.key) }}</mat-label>
            <div class="moneyDiv">
              <input
                currencyMask
                [options]="{ prefix: '£ ' }"
                matInput
                autocomplete="off"
                placeholder="0.00"
                formControlName="{{ group.key }}"
              />
            </div>
          </div>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <div class="inputDiv">
            <mat-label class="mat-body-strong">Total</mat-label>
            <div class="moneyDiv">
              <input
                currencyMask
                [options]="{
                                    prefix: '£ ',
                                    allowNegative: true
                                }"
                class="mat-body-strong"
                matInput
                readonly
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="totalValue"
              />
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
    <small *ngIf="+totalValue > authLimit" class="warning"
    >Authorisation Limit: £{{ authLimit }}. Total Amount is higher than
      your Authorisation Limit. You cannot amend this Work Order</small
    >

    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea
        matInput
        rows="8"
        formControlName="description"
      ></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Risks</mat-label>
      <mat-select [multiple]="true" formControlName="risks">
        <mat-option *ngFor="let risk of risks" [value]="risk">{{
            risk
          }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
  <mat-toolbar id="footerToolbar">
    <button
      mat-raised-button
      color="warn"
      class="delete"
      (click)="amendWorkOrder('Closed')"
      [disabled]="
                (workOrderForm.disabled &&
                    workOrder.status !== 'Awaiting Payment') ||
                workOrderForm.dirty ||
                amending
            "
    >
      Close Work Order
    </button>
    <button
      mat-raised-button
      *ngIf="workOrder.status === 'Awaiting Authorisation'"
      (click)="amendWorkOrder('Open')"
      [disabled]="
                workOrderForm.dirty ||
                workOrderForm.disabled ||
                +totalValue > authLimit ||
                amending
            "
    >
      {{ amending ? 'Authorising...' : 'Authorise' }}
    </button>
    <button
      mat-raised-button
      *ngIf="workOrder.status === 'Awaiting Invoice Approval'"
      (click)="amendWorkOrder('Awaiting Payment')"
      [disabled]="
                workOrderForm.dirty ||
                workOrderForm.disabled ||
                +totalValue > authLimit ||
                amending
            "
    >
      Approve Invoice
    </button>
  </mat-toolbar>
</mat-card>

<mat-expansion-panel *ngIf="!loading">
  <mat-expansion-panel-header>
    <mat-icon>notes</mat-icon>
    <mat-label>Audits</mat-label>
  </mat-expansion-panel-header>
  <mat-list dense>
    <div *ngFor="let audit of workOrder?.audits | reverse">
      <mat-list-item class="auditList">
        <mat-icon mat-list-icon>notes</mat-icon>
        <mat-label mat-line class="auditTitle"
        >{{ audit.date | date: 'dd-MM-yyyy - hh:mm a' }} -
          {{ audit.event }} By {{ audit.user }}
        </mat-label
        >
      </mat-list-item>
    </div>
  </mat-list>
</mat-expansion-panel>

<mat-toolbar *ngIf="messageBanner" id="messageBanner">
  <mat-label>{{ messageText }}</mat-label>
</mat-toolbar>

<app-footer-toolbar
  [cancelButton]="true"
  [saveButton]="true"
  [saveDisabled]="
        (workOrderForm && workOrderForm.invalid) || +totalValue > authLimit
    "
  [showToolBar]="
        workOrderForm && workOrderForm.dirty && !workOrderForm.disabled
    "
  (cancelClick)="resetForm()"
  (saveClick)="saveChanges()"
>
</app-footer-toolbar>
