import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PropertyDetailsService } from '../property-details/property-details.service';
import { Observable } from 'rxjs';
import { isAfter } from 'date-fns';
import { breakdown, Budget } from '../../interfaces/budget-interfaces';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable({
    providedIn: 'root',
})
export class BudgetsService {
    apiBaseUrl = environment.apiBaseUrl;
    apiKey = environment.apiKey;

    constructor(
        public http: HttpClient,
        public propertyDetails: PropertyDetailsService,
        public appConfig: AppConfigService
    ) {}

    async updateBudget(budgetData: Budget) {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        return this.http.put(this.apiBaseUrl + '/budgets/', budgetData, {
            headers,
        });
    }

    async getAllYears(): Promise<Observable<{ budgetYear: string }[]>> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        const params = new HttpParams().append(
            'site',
            this.appConfig.selectedSite
        );
        return this.http.get<{ budgetYear: string }[]>(
            this.apiBaseUrl + '/budgets/',
            { headers, params }
        );
    }

    async getSelectedBudgets(
        budgetYears: string[]
    ): Promise<Observable<Budget[]>> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        return this.http.post<Budget[]>(
            this.apiBaseUrl + '/budgets/',
            { budgetYears, site: this.appConfig.selectedSite },
            { headers }
        );
    }

    calculateBudgetYear(): string {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const budgetStartDate = new Date(currentYear, 8, 1);

        return (
            isAfter(currentDate, budgetStartDate)
                ? currentYear
                : currentYear - 1
        ).toString();
    }

    getUnitBreakdown(budgetYear: string, unitFull: string) {
        const headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        const params = new HttpParams()
            .append('budgetYear', budgetYear)
            .append('unitFull', unitFull)
            .append('site', this.appConfig.selectedSite);
        return this.http.get<breakdown[]>(
            this.apiBaseUrl + '/budget/unitbreakdown',
            { headers, params }
        );
    }

    generateServiceCharge(budgetYear: string, unitFull: string) {
        const headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        const params = new HttpParams()
            .append('budgetYear', budgetYear)
            .append('unitFull', unitFull)
            .append('site', this.appConfig.selectedSite);
        return this.http.get(
            this.apiBaseUrl + '/budget/generateservicecharge',
            {
                headers,
                params,
                responseType: 'text',
            }
        );
    }
}
