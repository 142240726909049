<app-page-toolbar [pageTitle]="'Property Search'"></app-page-toolbar>
<div class="mx-auto flex w-full flex-col max-w-7xl items-stretch">
  <app-cw-card [title]="'Select a Property'" [showFooter]="false" class="mt-5">
    <card-icon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
      </svg>
    </card-icon>
    <card-body>
      <div>
        <label for="combobox" class="block text-sm font-medium leading-6 text-gray-900">Start typing the Property
          address</label>
        <div class="relative mt-2">
          <input id="combobox" type="text" autocomplete="off"
                 class="w-full rounded-md font-poppins h-12 border-0 bg-slate-50 py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-cw-blue-light sm:text-sm sm:leading-6 placeholder-cw-bg-gray"
                 role="combobox" aria-controls="options" aria-expanded="false" [(ngModel)]="unitSearchTerm"
                 (ngModelChange)="getUnitSearchResults()" placeholder="Example: 1 Ezel Court">
          <ul
            class="absolute z-[500] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            id="options" role="listbox"
            *ngIf="unitSearchTerm && unitSearchTerm.length >= 1 && unitSearchResults && unitSearchResults.length >= 1  ">
            <li *ngFor="let unit of unitSearchResults; index as i;"
                class="relative hover:bg-gray-200 cursor-pointer py-2 pl-3 pr-9 text-gray-900" id="option-{{i}}"
                tabindex="0" role="option"
                (click)="submitViewProperty(unit.unitFull)" (keydown.enter)="submitViewProperty(unit.unitFull)">
              <span class="block truncate">{{ unit.value }}</span>
            </li>
          </ul>
        </div>
      </div>
    </card-body>


  </app-cw-card>

  <app-cw-card title="Property Search">
    <card-icon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
      </svg>
    </card-icon>
    <card-body>
      <p>Please enter a minimum of 3 characters</p>
      <div class="flex flex-col w-full justify-center items-center tablet:flex-row">
        <app-cw-select [options]="['leaseholder', 'tenant']" [controlName]="'field'" [formGroup]="propSearch"
                       [label]="'Field'" class="w-full tablet:w-2/5 tablet:mr-2">

        </app-cw-select>

        <app-cw-text [controlName]="'searchValue'" [formGroup]="propSearch" [label]="'Search Value'" [enterKey]="true"
                     (enterKeyed)="getSearchResults()" class="w-full tablet:w-3/5 ">

        </app-cw-text>
      </div>
    </card-body>
    <card-footer>
      <app-cw-primary-button [isDisabled]="propSearch.invalid || searching || disableList" [buttonText]="'Search'"
                             (onClick)="getSearchResults()"></app-cw-primary-button>

      <div class='w-full mt-5' *ngIf="searching">
        <div class='h-1.5 w-full bg-slate-100 overflow-hidden'>
          <div class='progress w-full h-full bg-cw-blue left-right'></div>
        </div>
      </div>

      <h3 *ngIf="searchResults" class="text-center my-2 font-poppins">Results: {{ searchResults.length }}</h3>
      <ul role="list"
          class="divide-y divide-gray-100 overflow-hidden bg-slate-50 shadow-sm ring-1 ring-gray-900/5 rounded-md mb-2">
        <li (click)="submitViewProperty(result?.unitFull)"
            *ngFor="let result of searchResults"
            class="relative flex justify-between py-2  px-8  hover:bg-gray-100 sm:px-6 cursor-pointer">
          <div class="flex items-center min-w-0 gap-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor"
                 class="h-6 w-6 flex-none rounded-full bg-gray-50"
            >
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>

            <div class="min-w-0 flex-auto">
              <p class="text-sm font-semibold text-gray-900 m-0 font-poppins">

                <span class="absolute inset-x-0 -top-px bottom-0"></span>
                {{
                  showSearchField === 'leaseholder'
                    ? result?.ownerDetails?.name
                    : result?.subLetDetails?.occupierDetails
                      ?.occupierName
                }}

              </p>
              <p class="mt-1 flex text-xs leading-5 text-gray-500 m-0 font-poppins">
                {{
                  showSearchField === 'leaseholder'
                    ? result?.ownerDetails?.email1
                    : result?.subLetDetails?.occupierDetails
                      ?.occupierEmail
                }}
              </p>
            </div>
          </div>
          <div class="flex shrink-0 items-center gap-x-4">
            <div class="hidden sm:flex sm:flex-col sm:items-end">
              <p class="text-sm leading-6 text-gray-900 m-0 font-poppins">{{ result?.unitFull }}</p>
            </div>
            <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd" />
            </svg>
          </div>
        </li>
      </ul>
    </card-footer>
  </app-cw-card>

</div>


<app-cwe-spinner *ngIf="loading || disableList"></app-cwe-spinner>
