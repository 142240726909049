import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-cw-secondary-button',
    templateUrl: './cw-secondary-button.component.html',
    styleUrls: ['./cw-secondary-button.component.css'],
})
export class CwSecondaryButtonComponent {
    @Input() buttonText: string = '';
    @Input() isDisabled: boolean = false;
    @Output() onClick = new EventEmitter();

    clicked() {
        this.onClick.emit();
    }
}
